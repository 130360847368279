import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PendingApprovals = () => {
    const navigate = useNavigate();
    const [applicationData, setApplicationData] = useState([]);

    useEffect(() => {
        const adminId = JSON.parse(sessionStorage.getItem('adminId'));
        if (!adminId) {
          navigate('/admin-login');
        }
    }, []);

    useEffect(() => {
        fetchPendingApprovals();
    }, []);

    const fetchPendingApprovals = async () => {
        try {
            const response = await axios.get('http://localhost:3001/api/pending-applications');
            if (response.status === 200) {
                const data = response.data;
                setApplicationData(data);
            } else if(response.status === 201){
                console.log("No new application found");
            }
            else {
              console.error('Failed to fetch data');
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    };

    const approveApplication = async (id) => {
        try 
        {
            const response = await axios.post(`http://localhost:3001/api/approve-application/${id}`);
            if (response.status === 200) 
            {
              fetchPendingApprovals();
            } else {
              console.error('Failed to approve application');
            }
        } catch (error) {
            console.error('Error approving application:', error);
        }
    };

    const rejectApplication = async (id) => {
        try {
            const response = await axios.post(`http://localhost:3001/api/reject-application/${id}`);
            if (response.status === 200) {
              // Handle success
              fetchPendingApprovals();
            } else {
              console.error('Failed to reject application');
            }
        } catch (error) {
            console.error('Error rejecting application:', error);
        }
    };
      
    return (
        <div className="verify-section w-full h-full">
            {applicationData.length > 0 ? 
            (
                <div className="py-8 md:p-2 mx-8 text-gray-500">
                    {applicationData.map((item, index) => (
                        <div key={item._id} className="bg-white p-4 my-4 shadow-md rounded-md px-2">
                            <div className="px-2"><span className="font-semibold">Store Name:</span> {item.storeName}</div>
                            <hr />
                            <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">Owner Name</div>
                                    <div>{item.ownerName}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">Email</div>
                                    <div>{item.email}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">Mobile</div>
                                    <div></div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">GSTIN</div>
                                    <div>{item.gstin}</div>
                                </div>  
                            </div>
                            <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">License Number</div>
                                    <div>{item.drugLicenseNumber}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">License Photo</div>
                                    <div><a href={item.drugLicenseImg} target="_blank"><img src={item.drugLicenseImg} alt="" className="w-7 h-7 rounded-md shadow-sm" /></a></div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">PAN Number</div>
                                    <div>{item.ownerPANNum}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">PAN Photo</div>
                                    <div><a href={item.ownerPANImg} target="_blank"><img src={item.ownerPANImg} alt="" className="w-7 h-7 rounded-md shadow-sm" /></a></div>
                                </div>  
                            </div>
                            <div className="w-full flex flex-row text-sm items-center px-2 mt-2">
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">City</div>
                                    <div>{item.city}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">State</div>
                                    <div>{item.state}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">Zipcode</div>
                                    <div>{item.zipCode}</div>
                                </div>
                                <div className="w-3/12 flex flex-col">
                                    <div className="font-semibold">Landmark</div>
                                    <div>{item.landmark}</div>
                                </div>  
                            </div>
                            <div className="w-full flex justify-end px-4 gap-4 mt-4">
                                <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 font-semibold rounded-sm shadow-md cursor-pointer" onClick={() => rejectApplication(item._id)}>Reject</button>
                                <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-1 font-semibold rounded-sm shadow-md cursor-pointer" onClick={() => approveApplication(item._id)}>Approve</button>
                            </div>
                        </div>
                    ))}
                </div>
            ):(
                <div className="w-full text-center font-semibold text-3xl mt-12 text-gray-500">No application pending</div>
            )}
        </div>
    );
};

export default PendingApprovals;