import React from 'react';

const OwnerDetails = () => {
    const ownerDetails = {
        ownerName: 'John Doe',
        panDetails: 'ABCDE1234F',
        contactNumber: '9876543210',
        email: 'john.doe@example.com',
    };

    return (
        <div className="details-page">
            <h2>Owner Details</h2>
            <p><strong>Owner Name:</strong> {ownerDetails.ownerName}</p>
            <p><strong>PAN Details:</strong> {ownerDetails.panDetails}</p>
            <p><strong>Contact Number:</strong> {ownerDetails.contactNumber}</p>
            <p><strong>Email:</strong> {ownerDetails.email}</p>
        </div>
    );
};

export default OwnerDetails;
