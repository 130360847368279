import axiosInstance from "../utils/axios";

export const handleLogout = async (navigate) => {
    const userId = JSON.parse(sessionStorage.getItem('user'))._id;
    try {
        await axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/logout`, {userId});
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('currentStep');
        sessionStorage.removeItem('partnerDetails');
        sessionStorage.removeItem('appliedForPartner');
        sessionStorage.removeItem('userToken');
        navigate('/');
    } catch (error) {
        console.error('Error during logout:', error);
    }
};
