import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../utils/axios';
import {FaMinus, FaTimes, FaTrash, FaChevronDown, FaCheck} from 'react-icons/fa';
import { PiWarningCircle } from "react-icons/pi";
import { IoAddCircleOutline } from "react-icons/io5";
import { HiMinusSm } from "react-icons/hi";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Bill = () => {
  const [suggestions, setSuggestions] = useState([]);
  // const [billData, setBillData] = useState([{ name: '', qty: 0, price: 0, rate: 0, amount: 0, discountRate: 0, discount: 0, netAmount: 0, stocks: 0, productType: '', unitsPerPack: 0 }]);
  const [billData, setBillData] = useState([{ name: '', medicineId: '', inventoryId: '',brand: '', unitsPerPack: 0, batchData: [{ batchId: '', batchNum: '', qty: 0, price: 0, rate: 0, amount: 0, discountRate: 0, discount: 0, netAmount: 0, stocks: 0, isActive: "true" }] }]);
  const [validBillData, setValidBillData] = useState([{ name: '', qty: 0, price: 0, rate: 0, amount: 0, discountRate: 0, discount: 0, netAmount: 0, stocks: 0, productType: '', unitsPerPack: 0 }]);
  const debounceTimer = useRef(null);
  const nameInputRefs = useRef([]);
  const quantityInputRefs = useRef([]);
  const [focusRowIndex, setFocusRowIndex] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountBeforeDiscount, setTotalAmountBeforeDiscount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [billCreateModal, setBillCreateModal] = useState({ open: false });
  const [billType, setBillType] = useState('purchase');
  const [customerName, setCustomerName] = useState('');
  const [storeName, setStoreName] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [additionalDiscount, setAdditionalDiscount] = useState('');
  const [amountReceived, setAmountReceived] = useState('');
  const [amountRefundable, setAmountRefundable] = useState('');
  const [partnerId, setPartnerId] = useState('');
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [additionalDetailPopup, setAdditionalDetailPopup] = useState(false);

  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const partnerDetails = JSON.parse(sessionStorage.getItem('partnerDetails'));
    setStoreName(partnerDetails.storeName);
    setStoreAddress(partnerDetails.storeLocation);
    if (!user) {
      navigate('/');
    }
    setPartnerId(user._id);
  }, [navigate]);

  useEffect(() => {
    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    // Initial check and add event listener
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
        window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleProductNameChange = async (e, index) => {
    const value = e.target.value;
    if (value.trim() === '') {
      setSuggestions([]);
      // updateBillData(index, { name: '', qty: 0, price: 0, rate: 0, amount: 0, discount: 0, netAmount: 0 });
      setBillData((prevBillData) => {
        const newBillData = [...prevBillData];
        newBillData[index] = {
          name: '', 
          medicineId: '', 
          inventoryId: '',
          brand: '', 
          unitsPerPack: 0, 
          batchData: [
            { 
              batchId: '', 
              batchNum: '', 
              qty: 0, 
              price: 0, 
              rate: 0, 
              amount: 0, 
              discountRate: 0, 
              discount: 0, 
              netAmount: 0, 
              stocks: 0,
              isActive: "true"
            }
          ]
        };
        return newBillData;
      });
    } else {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      const user = JSON.parse(sessionStorage.getItem('user'));
      const userIdParam = `userId=${user._id}`;
      const productNameParam = value ? `&productName=${value}` : '';
      const timer = setTimeout(async () => {
        try {
          const token = JSON.parse(sessionStorage.getItem('userToken'));
          const url = `${process.env.REACT_APP_API_STORE_URL}/medicines-detail?${userIdParam}${productNameParam}`;
          const response = await axiosInstance.get(url);
          if (response.status == 200) {
            const data = response.data;
            const newSuggestions = [...suggestions];
            newSuggestions[index] = data; // This line updates suggestions for the current row
            if(data.length>0)
            {
              setSuggestions(newSuggestions);
            }
          } else {
            console.error('Failed to fetch medicine data');
          }
        } catch (error) {
          console.error('Error fetching medicine data:', error);
        }
      }, 300);
      debounceTimer.current = timer;
      
      const newBillData = [...billData];
      newBillData[index].name = value;
      setBillData(newBillData);
    }
    // Update billData with the entered medicine name
  };

  const handleInputKeyDown = (e, rowIndex) => {
    const currentSuggestions = suggestions[rowIndex] || [];

    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault();
        handleArrowKey(-1, rowIndex);
        break;
      case 'ArrowDown':
        e.preventDefault();
        handleArrowKey(1, rowIndex);
        break;
      case 'Enter':
        e.preventDefault();
        const selectedSuggestion = currentSuggestions.find((suggestion) => suggestion.isSelected);
        if (selectedSuggestion) {
          handleSuggestionClick(selectedSuggestion, rowIndex);
        }
        break;
      default:
        break;
    }
  };

  const handleArrowKey = (direction, rowIndex) => {
    const currentSuggestions = suggestions[rowIndex] || [];
    const selectedIndex = currentSuggestions.findIndex((suggestion) => suggestion.isSelected);

    const newIndex = selectedIndex + direction;
    const isValidIndex = newIndex >= 0 && newIndex < currentSuggestions.length;

    const updatedSuggestions = currentSuggestions.map((suggestion, index) => ({
      ...suggestion,
      isSelected: isValidIndex && index === newIndex,
    }));

    setSuggestions((prevSuggestions) => {
      const newSuggestions = [...prevSuggestions];
      newSuggestions[rowIndex] = updatedSuggestions;
      return newSuggestions;
    });
  };

  const handleQtyChange = (e, index, batchIndex) => {
    const value = e.target.value;
    let itemPerPack = billData[index].unitsPerPack;
    let stocks = billData[index].batchData[batchIndex].stocks;
    if(value > (stocks*itemPerPack)){
      toast.error(`Total units of this product are ${(stocks*itemPerPack)}`, { position: 'top-right' });
      return;
    }
    const updatedBillData = [...billData];
    updatedBillData[index].batchData[batchIndex] = {
      ...updatedBillData[index].batchData[batchIndex],
      qty: value, 
      amount: parseFloat(updatedBillData[index].batchData[batchIndex].rate*value).toFixed(2), 
      discount: parseFloat(updatedBillData[index].batchData[batchIndex].discountRate*value).toFixed(2), 
      netAmount: parseFloat(updatedBillData[index].batchData[batchIndex].rate*value - updatedBillData[index].batchData[batchIndex].discountRate*value).toFixed(2)
    };
    setBillData(updatedBillData);
    // updateBillData(index, { ...billData[index].batchData[batchIndex], qty: value, amount: parseFloat(billData[index].batchData[batchIndex].rate*value).toFixed(2), discount: parseFloat(billData[index].batchData[batchIndex].discountRate*value).toFixed(2), netAmount: parseFloat(billData[index].batchData[batchIndex].rate*value - billData[index].batchData[batchIndex].discountRate*value).toFixed(2) });
  };
  useEffect(() => {
    const sumOfAmounts = billData.reduce((total, item) => {
      const batchNetAmount = item.batchData.reduce((batchTotal, batchItem) => batchTotal + parseFloat(batchItem.netAmount), 0);
      return total + batchNetAmount;
    }, 0);
    const totalWithDiscount = sumOfAmounts - (additionalDiscount ? parseFloat(additionalDiscount): 0);
    setTotalAmount(totalWithDiscount.toFixed(2));
    
    const totalAmountBeforeDiscount = billData.reduce((total, item) => {
      const batchNetamount = item.batchData.reduce((batchTotal, batchItem) => batchTotal + parseFloat(batchItem.amount), 0);
      return total + batchNetamount;
    }, 0);
    // const totalAmountBeforeDiscount = billData.reduce((total, item) => total + parseFloat(item.amount), 0);
    setTotalAmountBeforeDiscount(totalAmountBeforeDiscount.toFixed(2));
    
    const totalDiscount = totalAmountBeforeDiscount - totalWithDiscount;
    setTotalDiscount(totalDiscount.toFixed(2));
    
    if(amountReceived){
      const amt = amountReceived - totalWithDiscount;
      setAmountRefundable(amt.toFixed(2));
    }
  }, [billData, additionalDiscount, amountReceived]);

  useEffect(() => {
    const amt = amountReceived - totalAmount;
    if(!amountReceived){
      setAmountRefundable(0.00);
      return;
    }
    setAmountRefundable(amt.toFixed(2));
  }, [ amountReceived]);

  const addRow = (e, rowIndex) => {
    if(rowIndex+1 === billData.length)
    {
      const value = e.target.value;
      if (value.trim() === '') {
        return;
      }
      setBillData([...billData, 
        { 
          name: '', 
          medicineId: '', 
          inventoryId: '',
          brand: '', 
          unitsPerPack: 0, 
          batchData: [
            { 
              batchId: '', 
              batchNum: '', 
              qty: 0, 
              price: 0, 
              rate: 0, 
              amount: 0, 
              discountRate: 0, 
              discount: 0, 
              netAmount: 0, 
              stocks: 0,
              isActive: "true"
            }
          ]
        }]);
      setFocusRowIndex(billData.length);
    }else{
      setFocusRowIndex(rowIndex+1);
    }
  }
  const addNewRow = () => {
    setBillData([...billData, 
      { 
        name: '', 
        medicineId: '', 
        inventoryId: '',
        brand: '', 
        unitsPerPack: 0, 
        batchData: [
          { 
            batchId: '', 
            batchNum: '', 
            qty: 0, 
            price: 0, 
            rate: 0, 
            amount: 0, 
            discountRate: 0, 
            discount: 0, 
            netAmount: 0, 
            stocks: 0,
            isActive: "true"
          }
        ]
      }]);
    setFocusRowIndex(billData.length);
  }

  const removeRow = (rowIndex) => {
    if (billData.length > 1) {
      setTotalAmount(totalAmount - billData[rowIndex].rate*billData[rowIndex].qty);
      setBillData((prevBillData) => {
        const newBillData = [...prevBillData];
        newBillData.splice(rowIndex, 1);
        return newBillData;
      });
    }else{
      resetBill();
    }
  };

  const updateBillData = (index, newData) => {
    setBillData((prevBillData) => {
      const newBillData = [...prevBillData];
      newBillData[index] = { ...newData };
      return newBillData;
    });
  };
  

  const handleSuggestionClick = (suggestion, rowIndex) => {
    if (suggestion) {
      updateBillData(rowIndex, {
        inventoryId: suggestion.inventoryId,
        medicineId: suggestion.medicineId,
        name: suggestion.productName || '',
        brand: suggestion.brand,
        unitsPerPack: suggestion.unitsPerPack,
        batchData: suggestion.batchData.map(data => ({
          batchId: data.batchId,
          batchNum: data.batchNum,
          qty: '',
          price: parseFloat(data.price).toFixed(2) || 0,
          rate: parseFloat(data.price/suggestion.unitsPerPack).toFixed(2) || 0,
          amount: 0,
          discountRate: parseFloat(data.discountPrice/suggestion.unitsPerPack).toFixed(2) || 0,
          discount: 0,
          netAmount: 0,
          expiryDate: data.expiryDate,
          vendor: data.vendor,
          stocks: data.offlineStocks,
          isActive: data.isActive,
        })),
      });
    }
    setSuggestions([]);
    if (quantityInputRefs.current[rowIndex]) {
      quantityInputRefs.current[rowIndex].focus();
    }
  };
  

  useEffect(() => {
    if (focusRowIndex !== null && nameInputRefs.current[focusRowIndex]) {
      nameInputRefs.current[focusRowIndex].focus();
      setFocusRowIndex(null);
    }
  }, [focusRowIndex]);

  const createBill = async () => {
    // const filteredBillData = billData.filter(rowData => {
    //   return rowData.name.trim() !== '' && rowData.batchData.some(batchItem => batchItem.qty !== '' && batchItem.amount !== '0.00');
    // });
    const filteredBillData = billData.map(rowData => ({
      ...rowData,
      batchData: rowData.batchData.filter(batchItem => batchItem.qty !== '' && batchItem.amount !== 0)
    })).filter(rowData => rowData.name.trim() !== '' && rowData.batchData.length > 0);

    if (filteredBillData.length === 0) {
      toast.error('Please put atleast one product details to take print.', { position: 'top-right' });
      return;
    }

    const modifiedInventoryData = filteredBillData.flatMap(item =>
      item.batchData.map(batchItem => ({
        _id: batchItem.batchId,
        // partnerId: partnerId,
        offlineStocks: parseFloat(batchItem.stocks - parseInt(batchItem.qty) / item.unitsPerPack).toFixed(1),
      }))
    );
    
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    // basic Bill Details
    const billInfo = {
      partnerId: partnerId,
      customerName: customerName,
      additionalDiscount: additionalDiscount,
      totalBillAmount: totalAmount,
      amountReceived: amountReceived,
      amountRefundable: amountRefundable,
      billType: 'Offline',
      billDate: formattedDate,
    };

    // described Bill Details
    const billDetails = filteredBillData.flatMap(item => 
      item.batchData.map(batchItem => ({
        itemName: item.name,
        itemQty: batchItem.qty,
        itemPrice: batchItem.price,
        itemRate: batchItem.rate,
        itemAmount: batchItem.amount,
        itemDiscount: batchItem.discount, 
        itemNetAmount: batchItem.netAmount,
      }))
    );    
    const token = JSON.parse(sessionStorage.getItem('userToken'));
    try {
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_STORE_URL}/create-bill`, {
        modifiedInventoryData,
        billInfo,
        billDetails
      });
      if (response.status === 200) {
        toast.success('Bill has been generated successfully!.', { position: 'top-right' });
        setValidBillData([...filteredBillData]);
        setBillCreateModal({ open: true });
        closeAdditionalDetailPopup();
      }
    } catch (error) {
      console.error('Error creating bill:', error);
      toast.error('Failed to create bill. Please try again.', { position: 'top-right' });
    }
  }

  const resetBill = () => {
    setBillData([
      { 
        name: '', 
        medicineId: '', 
        inventoryId: '',
        brand: '', 
        unitsPerPack: 0, 
        batchData: [
          { 
            batchId: '', 
            batchNum: '', 
            qty: 0, 
            price: 0, 
            rate: 0, 
            amount: 0, 
            discountRate: 0, 
            discount: 0, 
            netAmount: 0, 
            stocks: 0,
            isActive: "true"
          }
        ]
      }]);
    setTotalAmount(0);
    setAdditionalDiscount(0.00);
    setAmountReceived('');
    setAmountRefundable('');
    setCustomerName('');
  }
  const handleCreateCancel = () => {
    setBillCreateModal({ open: false });
    resetBill();
  }
  const handlePrintBill = () => {
    resetBill();
    // Create a temporary container for printing
      const printContainer = document.createElement('div');

    // Clone the modalHeader and modalContent elements into the container
      const modalHeader = document.querySelector('.modal-header');
      const clonedHeader = modalHeader.cloneNode(true);
      printContainer.appendChild(clonedHeader);

      const modalContent = document.querySelector('.modal-content');
      const clonedContent = modalContent.cloneNode(true);
      printContainer.appendChild(clonedContent);

      // Apply print styles to the cloned elements
      const styles = document.createElement('style');
      styles.textContent = `
        /* Your print styles go here */
        @media print {
          body * {
            visibility: hidden;
          }
          html, body {
            height:100%; 
            overflow: hidden;
          }
          .print-content, .print-content * {
            visibility: visible;
          }
          .print-content {
            position: absolute;
            left: 0;
            top: 0;
          }
          .p-4 {
            padding: 1rem;
          }
          .text-gray-500 {
            --tw-text-opacity: 1;
            color: rgb(107 114 128 / var(--tw-text-opacity));
          }
          .flex {
            display: flex;
          }
          .flex-col {
            flex-direction: column;
          }
          .justify-center {
            justify-content: center;
          }
          .font-semibold {
            font-weight: 600;
          }
          .text-2xl {
              font-size: 1.5rem;
              line-height: 2rem;
          }
          .text-center {
              text-align: center;
          }
          .text-xs {
              font-size: 0.75rem;
              line-height: 1rem;
          }
          .w-4/5 {
              width: 80%;
          }
          .mx-auto {
              margin-left: auto;
              margin-right: auto;
          }
          .justify-between {
            justify-content: space-between;
          }
          @media (min-width: 768px)
          .md:overflow-y-auto {
              overflow-y: auto;
          }
          @media (min-width: 768px)
          .md:overflow-auto {
              overflow: auto;
          }
          @media (min-width: 768px)
          .md:max-h-[28rem] {
              max-height: 28rem;
          }
          .px-4 {
              padding-left: 1rem;
              padding-right: 1rem;
          }
          .overflow-y-scroll {
              overflow-y: scroll;
          }
          .overflow-hidden {
              overflow: hidden;
          }
          .gap-4 {
              gap: 1rem;
          }
          .max-h-[32rem] {
              max-height: 32rem;
          }
          .pb-4 {
            padding-bottom: 1rem;
          }
          .gap-2 {
              gap: 0.5rem;
          }
          .justify-end {
              justify-content: flex-end;
          }
          .border-[1px] {
            border-width: 1px;
          }
          .text-left {
            text-align: left;
          }
          .px-2 {
              padding-left: 0.5rem;
              padding-right: 0.5rem;
          }
          .border-collapse {
            border-collapse: collapse;
          }
          .m-0 {
            margin: 0px;
          }
        }
      `;
      printContainer.classList.add('print-content');
      printContainer.appendChild(styles);
      document.body.appendChild(printContainer);
      window.print();
      printContainer.remove();
      setBillCreateModal({ open: false });
  };

  const openAdditionalDetailPopup = () => {
    setAdditionalDetailPopup(true);
  }
  const closeAdditionalDetailPopup = () => {
    setAdditionalDetailPopup(false);
  }

  // const changeBatch = (rowIndex, batchId) => {
  //   const item = billData[rowIndex];
  //   if (item) {
  //     item.batchData.forEach(batchItem => {
  //       if (batchItem.batchId === batchId) {
  //         batchItem.isActive = "true";
  //       } else {
  //         batchItem.isActive = "false";
  //       }
  //     });
  //   } else {
  //     console.log("Item not found for rowIndex:", rowIndex);
  //   }
  //   setBillData(billData);
  // }
  const changeBatch = (rowIndex, batchId) => {
    setBillData(prevBillData => {
      const updatedBillData = [...prevBillData];
      const item = updatedBillData[rowIndex];
      if (item) {
        item.batchData.forEach(batchItem => {
          if (batchItem.batchId === batchId) {
            batchItem.isActive = "true";
          } else {
            batchItem.isActive = "false";
            batchItem.qty = '';
            batchItem.amount = 0;
            batchItem.rate = 0;
            batchItem.discountRate = 0;
            batchItem.netAmount = 0;
          }
        });
      } else {
        // Item not found for rowIndex: rowIndex
      }
      return updatedBillData;
    });
  }  
  
  return (
    <div className="text-sm h-full overflow-y-clip">
      { !isSmallScreen ? (
        <div>
          <div className="flex md:flex-row flex-col md:h-screen">
            <div className="w-full md:w-[80%]">
              <div className="flex flex-col md:flex-row h-full text-gray-600">
                <div className="w-full h-full overflow-y-scroll pb-16">
                  <div className="p-4 md:py-8 md:px-6 md:relative">
                    <div className="md:flex md:flex-row gap-2">
                      <div className="w-full flex flex-row justify-between">
                        <h2 className="text-xl font-semibold">Create Bill</h2>
                        <button className="border-[1px] rounded-sm cursor-pointer px-2 py-1 border-blue-500 font-semibold text-blue-500 hover:text-white hover:bg-blue-500 duration-200" onClick={resetBill}>Create New</button>
                      </div>
                    </div>
                    <div className="w-full bg-white p-2 mb-[.1rem] shadow-md rounded-md mt-4">
                      <div className="flex flex-row pt-4 gap-2">
                        <div className="w-5/12 flex gap-2">
                          {/* <div className="w-1/12">S.No</div> */}
                          <div className="w-10/12">Name</div>
                          <div className="w-2/12">Batch</div>
                        </div>
                        <div className="w-7/12 flex gap-2">
                          <div className="w-1/12">Qty</div>
                          <div className="w-2/12">MRP</div>
                          <div className="w-2/12">Rate</div>
                          <div className="w-2/12">Amount</div>
                          <div className="w-2/12">Discount</div>
                          <div className="w-2/12">Net Amount</div>
                          <div className="w-1/12"></div>
                        </div>
                      </div>
                      <div className="flex flex-col pt-4 gap-2">
                        {billData.map((rowData, rowIndex) => (
                          <div key={rowIndex} className="w-full flex gap-2">
                            <div className="w-5/12 flex gap-2">
                              {/* <div className="w-1/12">{rowIndex + 1}</div> */}
                              <div className="w-10/12">
                                <input type="text" className="w-full border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="Medicine Name" value={rowData.name} onChange={(e) => handleProductNameChange(e, rowIndex)} onKeyDown={(e) => handleInputKeyDown(e, rowIndex)} ref={(inputRef) => (nameInputRefs.current[rowIndex] = inputRef)} />
                                {suggestions[rowIndex] && (
                                  <div className="absolute w-4/12" id={`suggestion-div-${rowIndex}`}>
                                    <ul className="z-10 border border-gray-100 bg-white rounded-sm shadow-md">
                                      {suggestions[rowIndex].map((suggestion, suggestionIndex) => (
                                        <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion, rowIndex)} className={`px-4 py-2 hover:bg-blue-100 cursor-pointer ${ suggestion.isSelected ? 'bg-blue-100' : '' }`} > {suggestion.productName}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              <select className="w-2/12 border-[1px] shadow-sm rounded-sm focus:outline-none focus:border-blue-500" onChange={(e) => changeBatch( rowIndex, e.target.value )}>
                                {rowData.batchData.map((item, j) => (
                                  item.batchNum !== "" && (
                                    <option value={item.batchId}>#{String(item.batchNum).padStart(4, '0')}</option>
                                  )
                                ))}
                              </select>
                            </div>
                            {rowData.batchData.map((batch, i) => (
                              batch.isActive === "true" && ( 
                              <div className="w-7/12 flex gap-2">
                                <input type="number" className="w-1/12 border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="0" value={batch.qty} onChange={(e) => handleQtyChange(e, rowIndex, i)} onKeyDown={(e) => e.key === 'Enter' && (addRow(e, rowIndex))} ref={(inputRef) => (quantityInputRefs.current[rowIndex] = inputRef)} />
                                <input type="text" className="w-2/12 border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="0.00" value={batch.price} readOnly disabled />
                                <input type="text" className="w-2/12 border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="0.00" value={batch.rate} readOnly disabled />
                                <input type="text" className="w-2/12 border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="0.00" value={batch.amount} readOnly disabled />
                                <input type="text" className="w-2/12 border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="0.00" value={batch.discount} readOnly disabled />
                                <input type="text" className="w-2/12 border-[1px] shadow-sm rounded-sm p-1 focus:outline-none focus:border-blue-500" placeholder="0.00" value={batch.netAmount} readOnly disabled />
                                {rowData.name !=='' || (batch.qty !== 0 && batch.qty !== '') ? (
                                  <div className="w-1/12 flex items-center justify-center text-xl">
                                      <FaMinus className="bg-red-500 text-white rounded-full p-1 cursor-pointer hover:bg-red-600 shadow-md" onClick={() => removeRow(rowIndex)} />
                                  </div>
                                ):(
                                  <div className="w-1/12"></div>
                                )
                                }
                              </div>
                              )
                            ))}
                          </div>  
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-[20%] border-l-2">
              <div className="flex flex-col gap-2 p-4">
                  <label htmlFor="billType" className="font-medium text-sm text-gray-400">Type</label>
                  <select id="billType" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setBillType(e.target.value)}>
                    <option value="purchase">Purchase</option>
                    <option value="return">Return</option>
                  </select>
                  <label htmlFor="customerName" className="font-medium text-sm text-gray-400">Name</label>
                  <input type="text" value={customerName} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setCustomerName(e.target.value)} />
                  <label htmlFor="extraDiscount" className="font-medium text-sm text-gray-400">Additional Discount</label>
                  <input type="number" step="0.01" placeholder="0.00" value={additionalDiscount} className="w-full border-b-2 text-right border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setAdditionalDiscount(e.target.value)}/>
                  <label htmlFor="extraDiscount" className="font-medium text-sm text-gray-400">Total</label>
                  <input type="number" step="0.01" placeholder="0.00" value={totalAmount} className="w-full border-b-2 text-right border-gray-400 focus:outline-none focus:border-blue-500" disabled/>
                  <label htmlFor="extraDiscount" className="font-medium text-sm text-gray-400">Amount Received</label>
                  <input type="number" step="0.01" placeholder="0.00" value={amountReceived} className="w-full border-b-2 text-right border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e)=> setAmountReceived(e.target.value)}/>
                  <label htmlFor="extraDiscount" className="font-medium text-sm text-gray-400">Amount Refundable</label>
                  <input type="number" step="0.01" placeholder="0.00" value={amountRefundable} className="w-full border-b-2 text-right border-gray-400 focus:outline-none focus:border-blue-500" disabled/>
                  <div className="text-center"><button className="bg-blue-500 text-white px-6 py-2 rounded-sm mt-8 hover:bg-white hover:text-blue-500 border hover:shadow-sm border-blue-500" onClick={createBill}>Create Bill</button></div>
              </div>
            </div>
          </div>
          {billCreateModal.open && (
            <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
              <div className="bg-white rounded-md w-10/12 md:w-6/12">
                <div className="modal-header p-4 text-gray-500">
                  <div className="flex flex-col justify-center">
                    <h4 className="text-center font-semibold m-0 text-2xl">{storeName.toUpperCase()}</h4>
                    <h2 className="text-center font-semibold m-0 text-xs w-4/5 mx-auto">{storeAddress}</h2>
                  </div>
                  <div className="flex justify-between">
                    {customerName !== '' && (
                    <div><span className="font-semibold">Cust. Name:</span> {customerName}</div>
                    )}
                    <div><span className="font-semibold">Date:</span> 16/12/2023</div>
                  </div>
                  <div className="flex justify-between">
                    <div></div>
                    <div><span className="font-semibold">Bill No.:</span> #123</div>
                  </div>
                  <hr className="" />
                </div>
                <div className="modal-content px-4 flex flex-col max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                  <table className="text-sm border-[1px]">
                    <tr>
                      <th className="text-xs font-semibold text-left px-2">S.NO.</th>
                      <th className="text-xs font-semibold text-left px-2">Name</th>
                      <th className="text-xs font-semibold text-left px-2">Qty</th>
                      <th className="text-xs font-semibold text-left px-2">MRP</th>
                      <th className="text-xs font-semibold text-left px-2">Rate</th>
                      <th className="text-xs font-semibold text-left px-2">Amount</th>
                      <th className="text-xs font-semibold text-left px-2">Discount</th>
                      <th className="text-xs font-semibold text-left px-2">Net Amount</th>
                    </tr>
                    {validBillData.map((rowData, rowIndex) => (
                      <tr className="text-xs border border-collapse">
                        <td className="px-2">{rowIndex+1}</td>
                        <td className="px-2">{rowData.name}</td>
                        <td className="px-2">{rowData.qty}</td>
                        <td className="px-2">{rowData.price}</td>
                        <td className="px-2">{rowData.rate}</td>
                        <td className="px-2">{rowData.amount}</td>
                        <td className="px-2">{rowData.discount}</td>
                        <td className="px-2">{rowData.netAmount}</td>
                      </tr>
                  ))}
                  </table>
                  <div className="text-xs font-medium flex justify-end">
                    <div className="">
                      <table className="border-[1px] border-t-0">
                        <tr className="border border-collapse border-t-0">
                          <th className="text-left font-semibold pr-4">Bill Amount:</th>
                          <td className="text-right">{totalAmount}</td>
                        </tr>
                        <tr className="border border-collapse">
                          <th className="text-left font-semibold pr-4">Total Discount:</th>
                          <td className="text-right">200</td>
                        </tr>
                        <tr className="border border-collapse">
                          <th className="text-left font-semibold pr-4">Grand Total:</th>
                          <td className="text-right">4,888</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="p-4 modal-footer flex pb-4 justify-end gap-2">
                    <button onClick={handlePrintBill} className="text-white bg-blue-500 border px-5 py-1 cursor-pointer hover:bg-blue-600 rounded-sm">Print</button>
                    <button onClick={handleCreateCancel} className="text-red-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-red-500 py-1 border-red-500">Cancel</button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : 
      (
        <div>
          <h2 className="m-4 mb-3 text-lg font-semibold text-gray-700">New Bill</h2>
          <div className="">
            <div className="bg-slate-100 m-3 pb-3 rounded-md">
              <div className="flex flex-row px-3 py-2 gap-2 font-semibold border-b-[1px] justify-between text-slate-600">
                <div className="w-[47%]">PRODUCT</div>
                <div className="w-[18%]">RATE</div>
                <div className="w-[12%]">QTY</div>
                <div className="w-[20%]">AMOUNT</div>
                <div className="w-[3%]"></div>
              </div>
              <div className="flex flex-col gap-2 py-3 max-h-80 overflow-y-scroll">
              {billData.map((rowData, rowIndex) => (
                <div className="flex flex-col">
                  <div className="flex flex-row px-3 gap-2 text-gray-500 font-semibold items-center">
                    <input type="text" className="w-[47%] bg-white shadow-sm rounded-md p-2" placeholder='Product Name' value={rowData.name} onChange={(e) => handleProductNameChange(e, rowIndex)} onKeyDown={(e) => handleInputKeyDown(e, rowIndex)} ref={(inputRef) => (nameInputRefs.current[rowIndex] = inputRef)} />
                    {suggestions[rowIndex] && (
                      <div className="absolute w-[89%]" id={`suggestion-div-${rowIndex}`}>
                        <ul className="z-10 border border-gray-100 bg-white rounded-sm shadow-md">
                          {suggestions[rowIndex].map((suggestion, suggestionIndex) => (
                            <li key={suggestion.id} onClick={() => handleSuggestionClick(suggestion, rowIndex)} className={`px-4 py-2 hover:bg-blue-100 cursor-pointer ${ suggestion.isSelected ? 'bg-blue-100' : '' }`} > {suggestion.productName}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <select className="w-[30%] bg-white shadow-sm rounded-md p-2" onChange={(e) => changeBatch( rowIndex, e.target.value )}>
                      {rowData.batchData.map((item, j) => (
                        item.batchNum !== "" && (
                          <option value={item.batchId}>#{String(item.batchNum).padStart(4, '0')}</option>
                        )
                      ))}
                    </select>
                    {rowData.batchData.map((batch, i) => (
                        batch.isActive === "true" && ( 
                          <input type="number" min={0} step={1} className="w-[20%] bg-white shadow-sm rounded-md p-2 text-right" placeholder="0" value={batch.qty} onChange={(e) => handleQtyChange(e, rowIndex, i)} onKeyDown={(e) => e.key === 'Enter' && (addRow(e, rowIndex))} ref={(inputRef) => (quantityInputRefs.current[rowIndex] = inputRef)} />
                        )
                      ))}
                    <div className="w-[3%]"><FaTrash className="text-red-500" onClick={() => removeRow(rowIndex)} /></div>
                  </div>
                  {rowData.batchData.map((batch, i) => (
                    batch.isActive === "true" && batch.rate !== 0 && ( 
                    <div className="w-[95%] flex flex-row px-3 gap-2 text-gray-500 text-xs font-semibold items-center justify-end">
                      <div>₹{batch.rate} x {batch.qty === "" ? 0 : batch.qty} = </div>
                      <div>₹{batch.amount}</div>
                    </div>
                  )))}
                </div>
              ))}
              </div>
              {/* <div className="w-2/12 mx-3"><IoAddCircleOutline className="bg-[#46506e] rounded-md h-7 p-1 text-white w-full" /></div> */}
              <div className="w-2/12 mx-3"><IoAddCircleOutline className="bg-blue-500 rounded-md h-7 p-1 text-white w-full" onClick={addNewRow} /></div>
            </div>
            <div className="flex flex-col text-right mx-4">
              <div className="flex flex-row items-center gap-3">
                <div className="w-8/12 text-sm font-semibold text-gray-700">Bill Amount</div>
                <div className="w-1/12 text-sm text-gray-700 "><HiMinusSm /></div>
                <div className="w-3/12 text-sm font-semibold text-gray-500">₹ {totalAmountBeforeDiscount}</div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <div className="w-8/12 text-sm font-semibold text-gray-700">Total Discount</div>
                <div className="w-1/12 text-sm text-gray-700 "><HiMinusSm /></div>
                <div className="w-3/12 text-sm font-semibold text-gray-500">₹ {totalDiscount}</div>
              </div>
              <div className="flex flex-row items-center gap-3">
                <div className="w-8/12 text-sm font-semibold text-gray-700">Grand Total</div>
                <div className="w-1/12 text-sm text-gray-700 "><HiMinusSm /></div>
                <div className="w-3/12 text-sm font-semibold text-gray-500">₹ {totalAmount}</div>
              </div>
            </div>
          </div>
          <div className='text-center absolute bottom-0'>
            <div className="w-screen p-2 font-semibold text-lg">
              <button className="w-full bg-blue-500 text-white py-2 font-semibold text-lg" onClick={openAdditionalDetailPopup}>Create Bill</button>
            </div>
          </div>    
          {additionalDetailPopup && (
            <div className="bg-[#24242461] w-screen h-screen absolute top-0">
              <div className="innerDiv flex flex-col gap-4 bg-white p-4 rounded-t-xl m-auto absolute bottom-0 left-0 right-0">
                <div className="flex justify-center text-gray-300 text-xl"><FaChevronDown  className="" onClick={closeAdditionalDetailPopup}/></div>
                <div className="flex flex-col justify-between gap-3">
                  <label htmlFor="billType" className="font-medium text-sm text-gray-400">Type</label>
                  <select id="billType" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setBillType(e.target.value)}>
                    <option value="purchase">Purchase</option>
                    <option value="return">Return</option>
                  </select>
                </div>
                <div className="flex flex-col justify-between gap-3">
                  <label htmlFor="customerName" className="font-medium text-sm text-gray-400">Name</label>
                  <input type="text" value={customerName} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setCustomerName(e.target.value)} />
                </div>
                <div className="flex flex-col justify-between gap-3">
                  <label htmlFor="additionalDiscount" className="font-medium text-sm text-gray-400">Additional Discount</label>
                  <input type="text" value={additionalDiscount} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setAdditionalDiscount(e.target.value)} />
                </div>
                <div className="flex flex-col justify-between gap-3">
                  <label htmlFor="additionalDiscount" className="font-medium text-sm text-gray-400">Amount Received</label>
                  <input type="text" value={amountReceived} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setAmountReceived(e.target.value)} />
                </div>
                <div className="flex flex-col justify-between gap-3">
                  <label htmlFor="additionalDiscount" className="font-medium text-sm text-gray-400">Amount Refundable</label>
                  <input type="text" value={amountRefundable} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => setAmountRefundable(e.target.value)} disabled readOnly />
                </div>
                <button className="bg-blue-500 text-white font-semibold rounded-sm shadow-sm py-2" onClick={createBill}>Create ₹{totalAmount}</button>
              </div>
            </div>
          )}
          {billCreateModal.open && (
            <div className="bg-[#24242461] w-screen h-screen absolute top-0 flex items-center justify-center">
              <div className="w-10/12 flex flex-col gap-2 bg-white p-4 rounded-md">
                <div className="flex justify-center"><FaCheck className="text-5xl text-white p-2 rounded-full shadow-md bg-green-400" /></div>
                <div className="text-xl text-center text-gray-500">Bill has been generated successfully!</div>
                <div className="flex flex-row justify-around mt-4">
                  <button className="bg-blue-500 w-5/12 text-white font-semibold py-2 px-5 rounded-sm shadow-sm" onClick={handlePrintBill}>Print</button>
                  <button className="bg-gray-500 w-5/12 text-white font-semibold py-2 px-5 rounded-sm shadow-sm" onClick={handleCreateCancel}>Cancel</button>
                </div>
              </div>
            </div>
          )}
          <div className={`fixed hidden top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] items-center justify-center z-50 modal open`}>
            <div className="bg-white rounded-md w-10/12 md:w-6/12">
              <div className="modal-header p-4 text-gray-500">
                <div className="flex flex-col justify-center">
                  <h4 className="text-center font-semibold m-0 text-2xl">{storeName.toUpperCase()}</h4>
                  <h2 className="text-center font-semibold m-0 text-xs w-4/5 mx-auto">{storeAddress}</h2>
                </div>
                <div className="flex justify-between">
                  {customerName !== '' && (
                  <div><span className="font-semibold">Cust. Name:</span> {customerName}</div>
                  )}
                  <div><span className="font-semibold">Date:</span> 16/12/2023</div>
                </div>
                <div className="flex justify-between">
                  <div></div>
                  <div><span className="font-semibold">Bill No.:</span> #123</div>
                </div>
                <hr className="" />
              </div>
              <div className="modal-content px-4 flex flex-col max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                <table className="text-sm border-[1px]">
                  <tr>
                    <th className="text-xs font-semibold text-left px-2">S.NO.</th>
                    <th className="text-xs font-semibold text-left px-2">Name</th>
                    <th className="text-xs font-semibold text-left px-2">Qty</th>
                    <th className="text-xs font-semibold text-left px-2">MRP</th>
                    <th className="text-xs font-semibold text-left px-2">Rate</th>
                    <th className="text-xs font-semibold text-left px-2">Amount</th>
                    <th className="text-xs font-semibold text-left px-2">Discount</th>
                    <th className="text-xs font-semibold text-left px-2">Net Amount</th>
                  </tr>
                  {validBillData.map((rowData, rowIndex) => (
                    <tr className="text-xs border border-collapse">
                      <td className="px-2">{rowIndex+1}</td>
                      <td className="px-2">{rowData.name}</td>
                      <td className="px-2">{rowData.qty}</td>
                      <td className="px-2">{rowData.price}</td>
                      <td className="px-2">{rowData.rate}</td>
                      <td className="px-2">{rowData.amount}</td>
                      <td className="px-2">{rowData.discount}</td>
                      <td className="px-2">{rowData.netAmount}</td>
                    </tr>
                ))}
                </table>
                <div className="text-xs font-medium flex justify-end">
                  <div className="">
                    <table className="border-[1px] border-t-0">
                      <tr className="border border-collapse border-t-0">
                        <th className="text-left font-semibold pr-4">Bill Amount:</th>
                        <td className="text-right">{totalAmount}</td>
                      </tr>
                      <tr className="border border-collapse">
                        <th className="text-left font-semibold pr-4">Total Discount:</th>
                        <td className="text-right">200</td>
                      </tr>
                      <tr className="border border-collapse">
                        <th className="text-left font-semibold pr-4">Grand Total:</th>
                        <td className="text-right">4,888</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="p-4 modal-footer flex pb-4 justify-end gap-2">
                  <button onClick={handlePrintBill} className="text-white bg-blue-500 border px-5 py-1 cursor-pointer hover:bg-blue-600 rounded-sm">Print</button>
                  <button onClick={handleCreateCancel} className="text-red-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-red-500 py-1 border-red-500">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default Bill;
