import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../utils/axios';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const StoreDetails = () => {
    const [storeName, setStoreName] = useState('');
    const [drugLicNum, setDrugLicNum] = useState('');
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });
    const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
    const [searchText, setSearchText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: '', // Add your API key here
        libraries: ['places'],
    });

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);
                setMarkerPosition({ lat: latitude, lng: longitude });
                setMapCenter({ lat: latitude, lng: longitude });
                setLocationDescription(latitude, longitude);
            });
        }
    }, [isLoaded]);

    useEffect(() => {
        fetchPartnerDetails();
    }, []);

    const fetchPartnerDetails = async () => {
        try {
            const token = JSON.parse(sessionStorage.getItem('userToken'));
            const user = JSON.parse(sessionStorage.getItem('user'));
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_STORE_URL}/store`, {
                partnerId: user._id,
            });
            if (response.status === 200 && response.data.length > 0) {
                const result = response.data[0];
                setStoreName(result.storeName);
                setDrugLicNum(result.drugLicenseNumber);
                setLatitude(result.storeLatitude);
                setLongitude(result.storeLongitude);
                const url = result.drugLicenseImg;
                const fileName = new URL(url).pathname.split('/').pop();
                setSelectedFile({ name: fileName, url: result.drugLicenseImg });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleMarkerDragEnd = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLatitude(lat);
        setLongitude(lng);
        setMarkerPosition({ lat, lng });
        setLocationDescription(lat, lng);
    }, []);

    const setLocationDescription = useCallback((lat, lng) => {
        if (!isLoaded || !window.google || !window.google.maps) {
            console.error('Google Maps API is not loaded properly');
            return;
        }

        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(lat, lng);

        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setSearchText(results[0].formatted_address);
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }, [isLoaded]);

    const handleStoreNameChange = (e) => setStoreName(e.target.value);
    const handleFileInputChange = (e) => setSelectedFile(e.target.files[0]);
    const toggleEditMode = () => setIsEditMode((prevMode) => !prevMode);

    const handleSearchInputChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Left Column: Store Details Form */}
            <div className="">
                <div className="flex justify-between items-center pb-4">
                    <h2 className="text-xl font-bold text-gray-800">Store Details</h2>
                    <button
                        className={`text-white py-1 px-3 rounded-md ${isEditMode ? 'bg-green-500' : 'bg-blue-500'}`}
                        onClick={toggleEditMode}
                    >
                        {isEditMode ? 'Save' : 'Edit'}
                    </button>
                </div>

                <div className="pb-4">
                    <label className="block text-sm font-medium text-gray-600">Store Name</label>
                    <input
                        type="text"
                        id="storeName"
                        className={`w-full h-10 p-2 border rounded-md ${isEditMode ? 'bg-white' : 'bg-gray-200'}`}
                        placeholder="Store Name"
                        value={storeName}
                        onChange={handleStoreNameChange}
                        disabled={!isEditMode}
                    />
                </div>

                <div className="pb-4">
                    <label className="block text-sm font-medium text-gray-600">Drug License (Number)</label>
                    <input
                        type="text"
                        id="drugLicenseNum"
                        className="w-full h-10 p-2 border rounded-md bg-gray-200"
                        placeholder="ABC123"
                        value={drugLicNum}
                        disabled
                    />
                </div>

                <div className="pb-4">
                    <label className="block text-sm font-medium text-gray-600">Drug License (Scan)</label>
                    {selectedFile && selectedFile.url && (
                        <div className="mb-2">
                            <img src={selectedFile.url} alt="Drug License" className="w-full h-auto border rounded-md" />
                        </div>
                    )}
                    {isEditMode && (
                        <>
                            <input
                                type="file"
                                id="drugLicenseScan"
                                className="hidden"
                                onChange={handleFileInputChange}
                            />
                            <label
                                htmlFor="drugLicenseScan"
                                className="py-2 w-full block border rounded-md cursor-pointer text-center bg-blue-500 text-white"
                            >
                                Choose File
                            </label>
                        </>
                    )}
                    <span className="pl-1 text-gray-500">
                        {selectedFile ? selectedFile.name : 'No file chosen'}
                    </span>
                </div>
            </div>

            {/* Right Column: Map and Search Bar */}
            <div className="">
                <label>Store Location:</label>
                <div className="flex gap-1 mb-2">
                    <input
                        type="text"
                        id="locationSearch"
                        className="w-full h-10 p-2 border rounded-md"
                        placeholder="Search for a location"
                        value={searchText}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '200px', boxShadow: '0 0 5px gray' }}
                    center={mapCenter}
                    zoom={15}
                    options={mapOptions}
                    onClick={(e) => {
                        const lat = e.latLng.lat();
                        const lng = e.latLng.lng();
                        setLatitude(lat);
                        setLongitude(lng);
                        setMarkerPosition({ lat, lng });
                        setMapCenter({ lat, lng });
                    }}
                >
                    <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                    />
                </GoogleMap>

                {/* OR text and manual latitude/longitude input */}
                <div className="flex items-center justify-center py-4">
                    <span className="text-gray-500">OR</span>
                </div>

                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Latitude</label>
                        <input
                            type="number"
                            id="latitude"
                            className="w-full h-10 p-2 border rounded-md"
                            value={latitude}
                            onChange={(e) => {
                                const lat = parseFloat(e.target.value);
                                setLatitude(lat);
                                setMarkerPosition({ lat, lng: longitude });
                                setMapCenter({ lat, lng: longitude });
                            }}
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-600">Longitude</label>
                        <input
                            type="number"
                            id="longitude"
                            className="w-full h-10 p-2 border rounded-md"
                            value={longitude}
                            onChange={(e) => {
                                const lng = parseFloat(e.target.value);
                                setLongitude(lng);
                                setMarkerPosition({ lat: latitude, lng });
                                setMapCenter({ lat: latitude, lng });
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StoreDetails;
