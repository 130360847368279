import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Registration from './components/Registration';
import Login from './components/Login';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import TermsOfService from './components/policies/TermsOfService';
import CodeOfConduct from './components/policies/CodeOfConduct';
import Dashboard from './components/Dashboard';
import RegisterPartner from './components/RegisterPartner';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/adminDashboard/Dashboard';
import AuthGuard from './components/AuthGuard';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<AuthGuard redirectTo="/dashboard"><Login /></AuthGuard>} />
          <Route path="/registration" element={<AuthGuard redirectTo="/dashboard"><Registration /></AuthGuard>} />
          <Route path="/admin-login" element={<AuthGuard redirectTo="/admin-dashboard"><AdminLogin /></AuthGuard>} />
          <Route path="/admin-dashboard" element={<AuthGuard redirectTo="/admin-login"><AdminDashboard /></AuthGuard>} />
          
          {/* Protected Routes */}
          <Route path="/dashboard" element={<AuthGuard redirectTo="/dashboard"><Dashboard /></AuthGuard>} />
          <Route path="/partner-register" element={<AuthGuard redirectTo="/dashboard"><RegisterPartner /></AuthGuard>} />
          
          {/* Policy Pages */}
          <Route path="/polices/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/polices/termsofservice" element={<TermsOfService />} />
          <Route path="/polices/codeofconduct" element={<CodeOfConduct />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
