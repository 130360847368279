import React, { useState } from 'react';
import { FaStore, FaUser, FaCreditCard } from 'react-icons/fa';
import StoreDetails from './StoreDetails';
import OwnerDetails from './OwnerDetails';
import AccountDetails from './AccountDetails';

const Personal = () => {
  const [activeComponent, setActiveComponent] = useState('Store Details'); // Default to Store Details
  const [otp, setOtp] = useState(Array(4).fill('')); // OTP length of 4 digits
  const [otpEnabled, setOtpEnabled] = useState(false); // Disable OTP inputs initially
  const [error, setError] = useState('');
  const [isVerified, setIsVerified] = useState(false); // Track whether verification is successful

  const correctOtp = '1234'; // Hardcoded OTP for demo purposes

  const handleSendCode = () => {
    setOtpEnabled(true); // Enable OTP input boxes after sending
    setError(''); // Clear existing error
  };

  // Function to render the verification form
  const renderVerificationUI = () => (
    <div className="text-center py-10">
      <h2 className="text-xl font-semibold mb-4 mt-2">Enter Security Code</h2>
      <p className="mb-4">Send the security code to <strong>example@gmail.com</strong></p>
      <button
        className="bg-blue-500 text-white py-2 px-4 rounded mb-6"
        onClick={handleSendCode} // Send verification code and enable OTP input
      >
        Send Code
      </button>
      <div className="flex justify-center mb-4">
        {otp.map((char, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            className="w-12 h-12 text-center border border-gray-400 rounded-lg text-2xl mx-1"
            value={char}
            onChange={(e) => handleInputChange(index, e.target.value)} // Handle OTP input change
            disabled={!otpEnabled} // Disable input until Send button is clicked
          />
        ))}
      </div>
      {error && <p className="text-red-500 mb-4">{error}</p>}
    </div>
  );

  const widgets = [
    { title: 'Store Details', icon: <FaStore />, component: <StoreDetails /> },
    { title: 'Owner Details', icon: <FaUser />, component: <OwnerDetails /> },
    {
      title: 'Account Details',
      icon: <FaCreditCard />,
      component: isVerified ? <AccountDetails /> : renderVerificationUI()
    }
  ];

  const handleInputChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1); // Keep only one character
    setOtp(newOtp);

    // Automatically move to the next input if the current one is filled
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }

    // Check OTP when all inputs are filled
    if (newOtp.every((char) => char)) {
      const enteredOtp = newOtp.join('');
      if (enteredOtp === correctOtp) {
        setIsVerified(true); // Set verification state to true
        setOtp(Array(4).fill('')); // Reset OTP input
        setError('');
      } else {
        setError('Incorrect verification code');
      }
    } else {
      setError(''); // Clear error while typing
    }
  };

  return (
    <div className="p-6 text-gray-500">
      {/* Top buttons for switching between components */}
      <div className="flex justify-center gap-4 mb-6">
        {widgets.map((widget, index) => (
          <button
            key={index}
            className={`flex flex-grow justify-center items-center py-2 px-4 rounded-lg text-lg font-semibold shadow-md transition-all duration-300
              ${activeComponent === widget.title
                ? 'text-blue-500 scale-105 shadow-lg border-b-4 border-blue-500'
                : 'text-gray-600 hover:bg-blue-100 hover:shadow-lg border-b-4 border-transparent'}`}
            onClick={() => setActiveComponent(widget.title)}
          >
            <span className="text-2xl">{widget.icon}</span>
            <span className="ml-2">{widget.title}</span>
          </button>
        ))}
      </div>

      {/* Render the selected component with conditional background for Account Details */}
      <div
        className={`relative mt-4 p-6 border-[1px] border-gray-100 rounded-lg shadow-md transition-opacity duration-500 
          ${activeComponent === 'Account Details' && !isVerified ? 'bg-gray-100' : 'bg-white'}`}  // Conditional background for unverified account section
      >
        {widgets.find(widget => widget.title === activeComponent)?.component}
      </div>
    </div>
  );
};

export default Personal;
