import React from 'react';

const AccountDetails = () => {
    const accountDetails = {
        accountNumber: '1234567890',
    };

    return (
        <div className="details-page">
            <h2>Account Details</h2>
            <p><strong>Account Number:</strong> {accountDetails.accountNumber}</p>
        </div>
    );
};

export default AccountDetails;
