import axios from 'axios';

const token = JSON.parse(sessionStorage.getItem('userToken'));

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_STORE_URL,
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const token = JSON.parse(sessionStorage.getItem('userToken'));
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        const newToken = response.headers['authorization'];
        if (newToken) {
            const token = newToken.split(' ')[1];
            sessionStorage.setItem('userToken', JSON.stringify(token));
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/refresh-token`, {}, { withCredentials: true });
                const newAccessToken = response.data.accessToken;
                sessionStorage.setItem('userToken', JSON.stringify(newAccessToken));
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                console.error('Refresh token invalid or expired. Logging out the user.');
                sessionStorage.removeItem('userToken');
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
