import React, { useState, useEffect } from 'react';
// import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom'; 
import axios from 'axios';
// import { AiOutlineMail } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { BsCheck, BsCircleFill, BsEye, BsEyeSlash, BsX } from 'react-icons/bs';
import loginImage from '../img/loginPageImage.png';
import logoWhite from '../img/logoNameWhite.png';
import {MdArrowBackIosNew} from 'react-icons/md';
// import PhoneInput from 'react-phone-number-input';
import OtpInput from 'react-otp-input';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderOverlay from '../LoaderOverlay';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess, loginFailure } from '../store/slices/authSlice';

const Login = () => {
  const navigate = useNavigate();
  
  // Declare and initialize state variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [step, setStep] = useState(1);
  const [loginEmailValid, setLoginEmailValid] = useState(true);
  const [loginEmailExistError, setLoginEmailExistError] = useState(null);
  const [loginPassword,setLoginPassword] = useState('');
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [otpInvalidError, setOTPInvalidError] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpExpiration, setOtpExpiration] = useState(null); // Store OTP expiration time
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordMismatch,setPasswordMismatch] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [minCharState,setMinCharState] = useState(1);
  const [oneUpCaseState,setOneUpCaseState] = useState(1);
  const [oneNumState,setOneNumState] = useState(1);
  const [oneSpecSymbState,setOneSpecSymbState] = useState(1);
  const [loading, setLoading] = useState(false);

  const [remainingTime, setRemainingTime] = useState(30); // Initial remaining time in seconds
  const [timerVisible, setTimerVisible] = useState(true);
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleCnfPasswordVisibility = () => {
      setShowCnfPassword(!showCnfPassword);
    };

    const checkPassword = (value) => {
      setMinCharState(2);
      setOneUpCaseState(2);
      setOneNumState(2);
      setOneSpecSymbState(2);
      // Check for uppercase letter
      const uppercaseRegex = /[A-Z]/;
      if(uppercaseRegex.test(value)){
          setOneUpCaseState(3);
      }
      // Check for special symbol (you can define your own set of special symbols)
      const specialSymbolRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
      if(specialSymbolRegex.test(value)){
          setOneSpecSymbState(3);
      }
      // Check for number
      const numberRegex = /[0-9]/;
      if(numberRegex.test(value)){
          setOneNumState(3);
      }
      // Check for length (at least 8 characters)
      if(value.length >= 8){
          setMinCharState(3);
      }
    };
  const handlePasswordChange = (e) => {
      const value = e.target.value;
      var newValue = value.replace(/\s/g, '');
      setPassword(newValue);
      checkPassword(newValue);
  };
  const inputType = showPassword ? 'text' : 'password';
  const inputTypeCnf = showCnfPassword ? 'text' : 'password';

  useEffect(() => {
      // Check if OTP has expired
      if (otpExpiration && new Date() > new Date(otpExpiration)) {
          // OTP has expired, reset the step and OTP
          setStep(1);
          setOtp('');
          setOtpExpiration(null);
      }
  }, [otpExpiration]);

  useEffect(() => {
      let intervalId;
      // Function to decrement the remaining time and hide the timer when it reaches 0
      const updateTimer = () => {
          setRemainingTime((prevTime) => {
              if (prevTime === 1) {
                  clearInterval(intervalId);
                  setTimerVisible(false); // Hide the timer
              }
              return prevTime - 1;
          });
      };
      // Start the timer when the component mounts
      if (timerVisible) 
      {
          intervalId = setInterval(updateTimer, 1000);
      }
      // Clear the interval when the component unmounts
      return () => {
          clearInterval(intervalId);
      };
  }, [timerVisible]);

  const handleResend = async () => {
      // Add logic to resend the verification code here
      // You can also reset the timer if needed
      setOTPInvalidError(null);
      setOtp('');
      try {
          // Send user details to the backend
          const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/send-otp`, {
              email: loginEmail,
          });
          if (response.status === 201) {
              // Store OTP expiration time (5 minutes from now)
              const expirationTime = new Date();
              expirationTime.setMinutes(expirationTime.getMinutes() + 5);
              setOtpExpiration(expirationTime);
              setRemainingTime(15); // Reset the timer to 30 seconds
              setTimerVisible(true); // Show the timer again
          }
      } catch (error) {
          // console.error(error);
      }
  };

  const openModal = () => {
    setStep(1);
    setLoginEmail('');
    setLoginPassword('');
    setPassword('');
    setConfirmPassword('');
    setShowPassword(false);
    setShowLoginPassword(false);
    setShowCnfPassword(false);
    checkPassword('');
    setLoginEmailExistError(null);
    setOTPInvalidError(null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const toggleLoginPasswordVisibility = () => {
      setShowLoginPassword(!showLoginPassword);
  };
  const inputTypeLogin = showLoginPassword ? 'text' : 'password';

  const handleLogin = async () => {
    setLoginEmailExistError(null);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(loginEmail)) {
        setLoginEmailValid(false);
        return;
    }
    try {
        setLoading(true);
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
            loginEmail, loginPassword
        }, {
          withCredentials: true, // Send cookies with the request
        });
        if (response.status === 200) {
            if (response.data.message === 'Invalid email or password') {
                setLoading(false);
                setLoginEmailExistError(displayErrorWithLoginOptionMain(2));
            } else if (response.data.message === 'Login successful') {
                dispatch(loginSuccess(response.data));
                sessionStorage.setItem('user', JSON.stringify(response.data.user));
                sessionStorage.setItem('currentStep', JSON.stringify(response.data.currentStep));
                sessionStorage.setItem('partnerDetails', JSON.stringify(response.data.partnerDetails));
                sessionStorage.setItem('appliedForPartner', JSON.stringify(response.data.appliedForPartner));
                sessionStorage.setItem('userToken', JSON.stringify(response.data.accessToken));
                const userStatus = response.data.user.status;

                setLoading(false);
                if (userStatus === "Registered" || userStatus === "Pending Approval") {
                    navigate(response.data.appliedForPartner ? '/partner-register' : '/registration');
                } else if (userStatus === "Completed") {
                    navigate('/dashboard');
                }
            } else if (response.status === 201) {
                // 2FA code sent
                // setLoginStep(2);
            } else {
                dispatch(loginFailure('Invalid credentials'));
                setLoading(false);
                setLoginEmailExistError(displayErrorWithLoginOptionMain(2));
            }
        }
    } catch (error) {
        dispatch(loginFailure('Invalid credentials'));
        setLoading(false);
    }
  };

  const forgotPassword = async () => {
    setLoading(true);
    try {
        // Send user details to the backend
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/forgot-password`, {
            email: loginEmail
        });
        if (response.status === 200 && response.data.message === 'User not registered') 
        {
          //
          setLoading(false);
          setLoginEmailExistError(displayErrorWithLoginOptionMain(3));
        }
        else if (response.status === 200 && response.data.message === 'OTP send successful') 
        {
          // change the screen for otp
          setStep(2);
          const expirationTime = new Date();
          expirationTime.setMinutes(expirationTime.getMinutes() + 5);
          setOtpExpiration(expirationTime);
          setRemainingTime(15); // Reset the timer to 30 seconds
          setTimerVisible(true); // Show the timer again
        } else
        {
          // otp failed
        }
    } catch (error) {
        // console.error(error);
    }
  };

  const handleLoginPasswordChange = (e) => {
      const value = e.target.value;
      var newValue = value.replace(/\s/g, '');
      setLoginPassword(newValue);
      // checkPassword(newValue);
  }
  const handleLoginEmailChange = (e) => {
      const value = e.target.value;
      var newValue = value.replace(/\s/g, '');
      setLoginEmail(newValue);
      // checkPassword(newValue);
  }

  const handleLoginEmailFocus = () => {
      // Reset email input border color when it gains focus
      setLoginEmailValid(true);
  };
  
  const loginEmailBorderColor = loginEmailValid ? 'shadow-none border-gray-300' : 'shadow-bs border-red-500';

  const displayErrorWithLoginOptionMain = (mode) => {
      if(mode===2)
      {
        return (
          <span className="text-red-500 text-xs font-medium">
            Email or Password is incorrect! <span className="text-blue-500 font-normal underline cursor-pointer" onClick={(forgotPassword)}>Forgot Password?</span>
          </span>
        );
      }else if(mode===3)
      {
        return (
          <span className="text-red-500 text-xs font-medium">
            User not registered!
          </span>
        );
      }
      else{
        return (
          <span className="text-red-500 text-xs font-medium">
            Email does not exist! <span className="text-blue-500 font-normal underline cursor-pointer"><Link to="/registration">Register?</Link></span>
          </span>
        );
      }
  };

  const displayErrorOTPVerify = () => {
    return (
      <div className="w-full text-red-500 text-xs text-center font-medium">
        Invalid Code!
      </div>
    );
  };
  const handleVerifyOTP = async () => {
    try {
        // Send OTP to the backend for verification
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/verify-otp`, {
            email: loginEmail,
            otp,
        });

        if (response.status === 200) {
            // OTP verification successful
            setStep(3);
        } else {
            // Handle OTP verification failure
            setOTPInvalidError(displayErrorOTPVerify());
        }
    } catch (error) {
        //console.error(error);
    }
  };

  const changeState = async () => {
    setStep(1);
  }

  const handleSetPassword = async () => {
    if(minCharState !== 3 || oneUpCaseState !==3 || oneNumState !== 3 || oneSpecSymbState !== 3)
    {
        return;
    }
    if(password !== confirmPassword){
        setPasswordMismatch(true);
        return;
    }
    try {
        // Send password and email to the backend to set the password
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/set-password`, {
            email: loginEmail,
            password,
        });
        if (response.status === 200) {
            toast.success('Password updated successfully!', { position: 'top-right' });
            setIsModalOpen(false);
            setStep(1);
            setPassword('');
            setConfirmPassword('');
            checkPassword('');
            setLoginEmailExistError(null);
            setOTPInvalidError(null);
            navigate('/');
            // You can optionally navigate the user to a login page here
        } else {
            // Handle password setting failure
            toast.error('Password update fails.', { position: 'top-right' });
        }
      } catch (error) {
          // console.error(error);
      }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="bg-blue-500 text-white h-12 md:h-16 flex flex-col justify-center px-4 md:px-8">
        <div className="flex items-center">
          <h1 className="text-2xl font-semibold mr-1 md:mr-2"><img src={logoWhite} alt="MediSeen" className="md:w-32 w-28"/></h1>
          <p className="md:text-sm text-xs text-gray-300 relative top-1">Partners</p>
        </div>
      </header>
      <div className="flex flex-grow items-center justify-center bg-gray-100 pt-0">
        {/* <div className="bg-white shadow-md rounded-md p-8 max-w-sm w-full pt-0"> */}
        <div className="p-8 max-w-sm w-full pt-0">
          <img src={loginImage} alt="" className="mb-2" />
          <h4 className="text-center font-medium md:text-lg text-base mb-2">MediSeen Partner Dashboard</h4>
          <div>
            <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300 mb-2" onClick={openModal}>Login</button>
            <Link to="/registration"><button type="submit" className="w-full bg-white border border-blue-500 text-blue-500 p-2 rounded-md hover:bg-blue-100 transition duration-300">Register</button></Link>
          </div>
          <p className="text-center md:text-base text-sm font-medium mt-2 mb-1">By continuing, you agree to our</p>
          <div className="flex text-xs md:text-left text-center justify-between text-gray-600">
            <Link to="/polices/termsofservice" target="_blank" rel="noopener noreferrer">Terms of Service</Link>
            <span>|</span>
            <Link to="/polices/privacypolicy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
            <span>|</span>
            <Link to="/polices/codeofconduct" target="_blank" rel="noopener noreferrer">Code of Conduct</Link>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-md w-80">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Login</h2>
              <button
                type="button"
                className="text-gray-600 hover:text-gray-800 transition duration-300"
                onClick={closeModal}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <button
              type="button"
              className="w-full bg-white border border-blue-500 text-blue-500 p-2 rounded-md hover:bg-blue-100 transition duration-300 mb-2"
            >
              <FcGoogle className="inline-block mr-2" />
              Continue with Google
            </button>
            <button
              type="button"
              className="w-full bg-white border border-blue-500 text-blue-500 p-2 rounded-md hover:bg-blue-100 transition duration-300 mb-2"
            >
              <AiOutlineMail className="inline-block mr-2" />
              Continue with Email
            </button>
            <p className="text-center font-medium text-gray-500 mb-4">or</p>
            <div className="flex items-center">
              <PhoneInput international countryCallingCodeEditable={false} defaultCountry="IN" value={value} onChange={setValue}/>
            </div>
            <button
              type="button"
              className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300"
            >
              Generate OTP
            </button>
            <p className="mt-2 text-sm">
              <span className="text-sm text-gray-500">New to MediSeen?</span> 
              <Link to="/registration" className="text-blue-500 hover:text-blue-600">Register Now</Link>
            </p>
          </div>
        </div>
      )} */}
      {isModalOpen && (
        <div className="fixed inset-0 flex md:p-0 p-4 items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded-md shadow-md w-80">
            {step === 1 ? (
              <div>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Login</h2>
                  <button type="button" className="text-gray-600 hover:text-gray-800 transition duration-300" onClick={closeModal} >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
                {/* <button
                  type="button"
                  className="w-full bg-white border border-blue-500 text-blue-500 p-2 rounded-md hover:bg-blue-100 transition duration-300 mb-2"
                >
                <FcGoogle className="inline-block mr-2" />
                Continue with Google
                </button>
                <hr className="mt-2 mb-2" /> */}
                <div>
                  <input type="email" className={`w-full p-2 border my-2 rounded-md ${loginEmailBorderColor}`} placeholder="Enter email" value={loginEmail} onChange={handleLoginEmailChange} onFocus={handleLoginEmailFocus}/>
                  <div className="relative mt-2">
                    <input type={inputTypeLogin} className="w-full p-2 mb-2 border border-gray-300 rounded-md" placeholder="Enter password" value={loginPassword}  onChange={handleLoginPasswordChange} />
                    <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={toggleLoginPasswordVisibility} >
                      {showLoginPassword ? <BsEyeSlash /> : <BsEye />}
                    </div>
                  </div>
                  {loginEmailExistError}
                </div>
                <button
                  type="button"
                  className="w-full bg-blue-500 text-white p-2 mt-2 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleLogin}
                  >
                  Log in
                </button>
                <p className="mt-2 text-sm">
                  <span className="text-sm text-gray-500">New to MediSeen? </span> 
                  <Link to="/registration"><span to="/registration" className="text-blue-500 hover:text-blue-600 cursor-pointer">Register Now</span></Link>
                </p>
              </div>
            ) : step === 2 ? (
              <div>
                <span className="cursor-pointer pb-6" onClick={changeState}><MdArrowBackIosNew className="text-gray-500 text-xl mt-2" /></span>
                <div className="text-center font-bold text-xl m-4 mb-6 text-gray-600">Verification Code</div>
                <div className="text-center m-4 text-gray-500 mb-8">
                    <p className="text-sm">Please enter the verification code sent to <span className="font-bold">{loginEmail}</span></p>
                </div>
                <div className="otpParent text-gray-500 pl-4 pr-4">
                    <OtpInput value={otp} onChange={setOtp} numInputs={6} inputStyle={'inputStyle'} shouldAutoFocus={true} inputType={'tel'} renderSeparator={''} renderInput={(props) => <input {...props} />} />
                </div>
                {otpInvalidError}
                <div className="text-center mt-8 mb-2 text-gray-500 text-sm"><p>Didn't receive the code in{' '}<span className="font-semibold">{timerVisible ? `00:${remainingTime.toString().padStart(2, '0')}` : '00:00'}</span>?</p></div>
                  <div className="text-center mb-8 font-medium text-gray-500 text-sm">
                      {timerVisible ? (
                          <span className="pr-2 pl-2 cursor-default">Resend</span>
                          ) : (
                          <span className="pr-2 pl-2 text-blue-500 cursor-pointer" onClick={handleResend}>
                              Resend
                          </span>
                      )}
                  </div>
                  <button type="button" className="w-full bg-blue-500 mb-4 text-white p-2 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleVerifyOTP}>Verify Code</button>
              </div>
            ) : (
              <div className="mt-4 mb-4">
                <label className="block text-sm font-medium mb-1 text-gray-600">Password</label>
                <div className="relative">
                    <input type={inputType} className="w-full p-2 mb-2 border border-gray-300 rounded-md pr-10" value={password} onChange={handlePasswordChange} />
                    <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={togglePasswordVisibility} >
                        {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </div>
                </div>
                {/* <input type="password" className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={password} onChange={handlePasswordChange} /> */}
                <label className="block text-sm font-medium mb-1 text-gray-600">Confirm Password</label>
                <div className="relative">
                    <input type={inputTypeCnf} className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    <div className="absolute top-0 right-0 h-full flex items-center pr-2 cursor-pointer" onClick={toggleCnfPasswordVisibility} >
                        {showCnfPassword ? <BsEyeSlash /> : <BsEye />}
                    </div>
                </div>
                {/* <input type="password" className="w-full p-2 mb-2 border border-gray-300 rounded-md" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} /> */}
                <div>
                    {minCharState === 1 ? (
                        <div className="text-gray-600">
                        <span>
                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                        </span>
                        8 characters minimum
                        </div>
                    ) : minCharState === 2 ? (
                        <div className="text-red-600">
                        <span>
                            <BsX className="inline-block mr-1 text-lg" />
                        </span>
                        8 characters minimum
                        </div>
                    ) : (
                        <div className="text-green-600">
                        <span>
                            <BsCheck className="inline-block mr-1 text-lg" />
                        </span>
                        8 characters minimum
                        </div>
                    )}

                    {oneUpCaseState === 1 ? (
                        <div className="text-gray-600">
                        <span>
                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                        </span>
                        At least one uppercase letter
                        </div>
                    ) : oneUpCaseState === 2 ? (
                        <div className="text-red-600">
                        <span>
                            <BsX className="inline-block mr-1 text-lg" />
                        </span>
                        At least one uppercase letter
                        </div>
                    ) : (
                        <div className="text-green-600">
                        <span>
                            <BsCheck className="inline-block mr-1 text-lg" />
                        </span>
                        At least one uppercase letter
                        </div>
                    )}

                    {oneNumState === 1 ? (
                        <div className="text-gray-600">
                        <span>
                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                        </span>
                        At least one number
                        </div>
                    ) : oneNumState === 2 ? (
                        <div className="text-red-600">
                        <span>
                            <BsX className="inline-block mr-1 text-lg" />
                        </span>
                        At least one number
                        </div>
                    ) : (
                        <div className="text-green-600">
                        <span>
                            <BsCheck className="inline-block mr-1 text-lg" />
                        </span>
                        At least one number
                        </div>
                    )}

                    {oneSpecSymbState === 1 ? (
                        <div className="text-gray-600">
                        <span>
                            <BsCircleFill className="inline-block mr-1 text-xsm" />
                        </span>
                        At least one special symbol
                        </div>
                    ) : oneSpecSymbState === 2 ? (
                        <div className="text-red-600">
                        <span>
                            <BsX className="inline-block mr-1 text-lg" />
                        </span>
                        At least one special symbol
                        </div>
                    ) : (
                        <div className="text-green-600">
                        <span>
                            <BsCheck className="inline-block mr-1 text-lg" />
                        </span>
                        At least one special symbol
                        </div>
                    )}
                    {isPasswordMismatch === true ? (
                        <div className="text-red-600">
                        <span>
                            <BsX className="inline-block mr-1 text-lg" />
                        </span>
                        Password match failed
                        </div>
                    ) : (
                        <div className="hidden"></div>
                    )
                    }
                    </div>
                <button type="button"
                className="w-full bg-blue-500 text-white p-2 mt-4 rounded-md hover:bg-blue-600 transition duration-300" onClick={handleSetPassword}>Set Password</button>
              </div>
            )}  
          </div>
        </div>
      )}
      <ToastContainer />
      <div className="z-50">
        <LoaderOverlay loading={loading} />
      </div>
    </div>
  );
};
export default Login;