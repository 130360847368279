import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { refreshAccessToken } from '../utils/auth';
import LoaderOverlay from '../LoaderOverlay';
import axios from 'axios';

const AuthGuard = ({ children, redirectTo }) => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true); // Manage loading state
    const accessToken = sessionStorage.getItem('userToken');
    const user = JSON.parse(sessionStorage.getItem('user'));
    
    const isAuthenticated = !!user;
    const userStatus = user?.status; // Assuming user has a status field

    useEffect(() => {
        const checkRefreshToken = async () => {
            try {
                // Make an API call to check if refreshToken exists in cookies (handled by backend)
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/check-refresh-token`, { withCredentials: true });
                const hasRefreshToken = response.data.hasRefreshToken;

                if (!accessToken && hasRefreshToken) {
                    // Refresh access token only if refreshToken is available
                    await refreshAccessToken();
                }
            } catch (error) {
                console.error('Error checking refresh token:', error);
            } finally {
                setIsLoading(false); // Update loading state after checking
            }
        };

        checkRefreshToken();
    }, [accessToken]);

    if (isLoading) {
      return (
        <div className="z-50">
            <div className="loader-overlay absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white"></div>
        </div>
      );
    }

    if (isAuthenticated) {
        // Redirect authenticated users from login or registration pages
        if (location.pathname === '/' || location.pathname === '/registration') {
            return <Navigate to={redirectTo} replace />;
        }

        // Additional logic for user status-based redirection
        if (userStatus === 'Registered' || userStatus === 'Pending Approval') {
            if (location.pathname !== '/partner-register') {
                return <Navigate to="/partner-register" replace />;
            }
        } else if (userStatus === 'Completed') {
            if (location.pathname !== '/dashboard') {
                return <Navigate to="/dashboard" replace />;
            }
        }
    } else {
        // Redirect unauthenticated users from protected routes
        if (location.pathname !== '/' && location.pathname !== '/registration') {
            return <Navigate to="/" replace />;
        }
    }

    return children;
};

export default AuthGuard;
