import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../utils/axios';
import { FaEdit, FaTrash, FaTrashAlt, FaFilter, FaTimes, FaArrowRight, FaSearch, FaExclamationTriangle } from 'react-icons/fa';
import { FaChevronLeft } from "react-icons/fa6";
import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import defaultImage from '../../img/default_img.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AddProductModal = ({ isOpen, onClose, onAddProduct }) => {
    const [vendorData, setVendorData] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);
    const [debounceTimer, setDebounceTimer] = useState(null);

    const [medicineId, setMedicineId] = useState('');
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productCost, setProductCost] = useState('');
    const [discountPrice, setDiscountPrice] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [productBrand, setProductBrand] = useState('');
    const [productComposition, setProductComposition] = useState('');
    const [productGroup, setProductGroup] = useState('');
    const [productVendor, setProductVendor] = useState('');
    const [onlineUnits,setOnlineSellUnit] = useState('');
    const [offlineUnits,setOfflineSellUnit] = useState('');
    const [itemsInStock, setItemsInStock] = useState('');
    const [productType, setProductType] = useState('');
    const [unitsPerPack, setUnitsPerPack] = useState('');
    const [quantity, setQuantity] = useState('');
    const [unit, setUnit] = useState('');
    const [previewImages, setPreviewImages] = useState([]);
    const categories = ['Select', 'Pain Relief', 'Skin Care', 'Cold & Flu Remedies', 'Digestive Health', 'Vitamins & Supplements', 'Allergy & Sinus Relief', 'Baby Care', 'Drinks & Supplements', 'Glocumeters & Test Strips', 'Women Care', 'Health Devices', 'Ayurveda', 'Personal Care', 'Sexual Wellness', 'Other'];

    useEffect(() => {
        fetchVendorData();
    }, []);

    const fetchVendorData = async () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        try {
            const token = JSON.parse(sessionStorage.getItem('userToken'));
            const url = `${process.env.REACT_APP_API_STORE_URL}/vendor?userId=${user._id}`;
            const response = await axiosInstance.get(url);
            if (response.status == 200) {
                const data = response.data;
                setVendorData(data);
            } else {
                console.error('Failed to fetch vendors data');
            }
        } catch (error) {
          console.error('Error fetching vendors data:', error);
        }
    };

    const handleProductNameChange = async (e) => {
        const value = e.target.value;
    
        if (value.trim() === '') {
            setSuggestions([]);
            setProductName(value);
            return;
        }
        setProductName(value);

        setSelectedSuggestion(null);
        setProductBrand('');
        setProductComposition('');
        setProductType('');
        setUnitsPerPack(0);
        setQuantity(0);
        setUnit('');
        setPreviewImages([]);
        
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        const timer = setTimeout(async () => {
            try {
                const token = JSON.parse(sessionStorage.getItem('userToken'));
                const url = `${process.env.REACT_APP_API_STORE_URL}/medicines?q=${value}`;
                const response = await axiosInstance.get(url);
                if (response.status == 200) {
                    const data = response.data;
                    setSuggestions(data);
                } else {
                    console.error('Failed to fetch medicine data');
                }
            } catch (error) {
                console.error('Error fetching medicine data:', error);
            }
        }, 300);
        setDebounceTimer(timer);
    };    

    const handleSuggestionClick = (suggestion) => {
        setSelectedSuggestion(suggestion);
        setMedicineId(suggestion._id);
        setProductName(suggestion.productName);
        setProductBrand(suggestion.brand);
        setProductComposition(suggestion.composition);
        setProductType(suggestion.productType);
        setUnitsPerPack(suggestion.unitsPerPack);
        setQuantity(suggestion.quantity);
        setUnit(suggestion.unit);
        const imageUrls = [
            suggestion.firstImgUrl,
            suggestion.secondImgUrl,
            suggestion.thirdImgUrl,
        ].filter(Boolean);
        setPreviewImages(imageUrls);
        setSuggestions([]);
    };

    const handleInputBlur = () => {
        setTimeout(() => {
            setSuggestions([]);
        }, 300);
    };
    const handleAddProduct = () => {
        if(!productName){
            toast.error('Product name cannot be blank.', { position: 'top-right' });
            return;
        }
        if(!productBrand){
            toast.error('Product brand cannot be blank.', { position: 'top-right' });
            return;
        }
        if(!productPrice || productPrice==0){
            toast.error('Product price is mandatory.', { position: 'top-right' });
            return;
        }
        if(!productCost || productCost==0){
            toast.error('Product cost is mandatory.', { position: 'top-right' });
            return;
        }
        if(!discountPrice){
            setDiscountPrice(0);
        }
        if(!expiryDate){
            toast.error('Expiry date cannot be blank.', { position: 'top-right' });
            return;
        }
        if(!productVendor){
            setProductVendor('');
        }
        if(!itemsInStock){
            setItemsInStock(0);
        }
        if(!onlineUnits){
            setOnlineSellUnit(0);
        }
        if(!offlineUnits){
            setOfflineSellUnit(0);
        }
        let newProduct = null;
        if(selectedSuggestion !== null)
        {
            newProduct = {  
                medicineId: medicineId,
                price: parseFloat(productPrice),
                cost: parseFloat(productCost),
                discountPrice: parseFloat(discountPrice),
                expiryDate: expiryDate,
                vendor: productVendor,
                stock: parseInt(itemsInStock),
                batchNum: 0,
                onlineUnits: parseInt(onlineUnits),
                offlineUnits: parseInt(offlineUnits),
            };
        }
        setProductName('');
        setProductBrand('');
        setProductPrice(0);
        setProductCost(0);
        setDiscountPrice(0);
        setProductComposition('');
        setProductGroup('');
        setProductVendor('');
        setItemsInStock(0);
        setOnlineSellUnit(0);
        setOfflineSellUnit(0);
        setProductType('');
        setUnitsPerPack(0);
        setQuantity(0);
        setUnit('');
        setPreviewImages([]);  
        onAddProduct(newProduct);
        onClose();
    };
    return (
        <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] text-sm flex items-center justify-center z-50 modal ${isOpen ? 'open' : 'hidden'}`}>
            <div className="modal-content bg-white rounded-md w-10/12 md:w-3/6">
                <div className="header p-4">
                    <h2 className="text-2xl font-semibold">Add Product</h2>
                    <hr className="md:py-2" />
                </div>
                <div className="px-4 flex flex-col gap-2 md:gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Product Name:</label>
                        <div className="md:w-9/12">
                            <input type="text" value={productName} className="border-[1px] shadow-sm rounded-sm w-full p-1 focus:outline-none focus:border-blue-500" onBlur={handleInputBlur} onChange={handleProductNameChange} />
                            {suggestions.length > 0 && (
                            <div className="absolute w-4/12">
                                <ul className="z-10 border border-gray-100 bg-white rounded-sm shadow-md">
                                    {suggestions.map((suggestion) => (
                                        <li
                                            key={suggestion.id}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                            className="px-4 py-2 hover:bg-blue-100 cursor-pointer"
                                        >
                                            {suggestion.productName}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Brand:</label>
                        <input type="text" value={productBrand} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setProductBrand(e.target.value)} readOnly disabled/>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Composition:</label>
                        <input type="text" value={productComposition} className="border-[1px] shadow-sm rounded-sm md:w-9/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setProductComposition(e.target.value)} readOnly disabled />                    
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">MRP:</label>
                        <input type="number" min={0} value={productPrice} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setProductPrice(e.target.value)} />
                        <div className="hidden md:block md:w-1/12"></div>
                        <label className="md:w-2/12">Cost:</label>
                        <input type="number" min={0} value={productCost} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setProductCost(e.target.value)} />                    
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Discount (Rs):</label>
                        <input type="number" min={0} value={discountPrice} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setDiscountPrice(e.target.value)} />                    
                        <div className="hidden md:block md:w-1/12"></div>
                        <label className="md:w-2/12">Expiry Date:</label>
                        <input type="month" value={expiryDate} className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setExpiryDate(e.target.value)} />   
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Vendor:</label>
                        <select value={productVendor} className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setProductVendor(e.target.value)} >
                            <option value="">Select</option>
                            {vendorData.map((vendor) => (
                            <option key={vendor._id} value={vendor._id}>
                                {vendor.name}
                            </option>
                            ))}
                        </select>
                        <div className="hidden md:block md:w-1/12"></div>
                        <label className="md:w-2/12">Stock:</label>
                        <input type="number" min={0} value={itemsInStock} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setItemsInStock(e.target.value)} />                    
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Online Units:</label>
                        <input type="number" min={0} value={onlineUnits} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setOnlineSellUnit(e.target.value)} />
                        <div className="hidden md:block md:w-1/12"></div>
                        <label className="md:w-2/12">Offline Units:</label>
                        <input type="number" min={0} value={offlineUnits} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setOfflineSellUnit(e.target.value)} />                    
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Product Type:</label>
                        <select value={productType} className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setProductType(e.target.value)} disabled>
                            <option value="" className="text-gray-400" disabled>select</option>
                            <option value="bottles">Bottles</option>
                            <option value="capsules">Capsules</option>
                            <option value="captabs">Captabs</option>
                            <option value="creams">Creams</option>
                            <option value="drops">Drops</option>
                            <option value="facewash">Facewash</option>
                            <option value="gels">Gels</option>
                            <option value="implants">Implants</option>
                            <option value="infusions">Infusions</option>
                            <option value="injections">Injections</option>
                            <option value="instacaps">Instacaps</option>
                            <option value="kits">Kits</option>
                            <option value="lotions">Lotions</option>
                            <option value="lozenges">Lozenges</option>
                            <option value="MDI">MDI</option>
                            <option value="ointments">Ointments</option>
                            <option value="paste">Paste</option>
                            <option value="patches">Patches</option>
                            <option value="powders">Powders</option>
                            <option value="soaps">Soaps</option>
                            <option value="solutions">Solutions</option>
                            <option value="sprays">Sprays</option>
                            <option value="strips">Strips</option>
                            <option value="suppositories">Suppositories</option>
                            <option value="suspensions">Suspensions</option>
                            <option value="syrup">Syrup</option>
                            <option value="syrups">Syrup</option>
                            <option value="tabcaps">Tabcaps</option>
                            <option value="tablets">Tablets</option>
                        </select>
                        <div className="hidden md:block md:w-1/12"></div>
                        <label className="md:w-2/12">Unit Per Pack:</label>
                        <input type="number" min={0} value={unitsPerPack} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setUnitsPerPack(e.target.value)} disabled />
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <label className="md:w-3/12">Quantity:</label>
                        <input type="number" min={0} value={quantity} placeholder='0' className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" onChange={(e) => setQuantity(e.target.value)} disabled />
                        <div className="hidden md:block md:w-1/12"></div>
                        <label className="md:w-2/12">Unit:</label>
                        <select className="border-[1px] shadow-sm rounded-sm md:w-3/12 p-1 focus:outline-none focus:border-blue-500" value={unit} onChange={(e) => setUnit(e.target.value)} disabled>
                            <option value="">select</option>
                            <option value="mg">mg</option>
                            <option value="mcg">mcg</option>
                            <option value="ml">ml</option>
                            <option value="gm">gm</option>
                        </select>
                    </div>
                    <div className="modal-footer flex pb-4 justify-end gap-2">
                        <button onClick={handleAddProduct} className="text-blue-500 bg-white border px-5 py-1 cursor-pointer hover:text-white hover:bg-blue-500 rounded-sm border-blue-500">Add</button>
                        <button onClick={onClose} className="text-gray-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Inventory = () => {
    const [inventoryData, setInventoryData] = useState([]);
    const [initialInventoryData, setInitialInventoryData] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState('Select');
    const [selectedBrand, setSelectedBrand] = useState('Select');
    const [searchTerm, updateSearchTerm] = useState('');
    const debounceTimer = useRef(null);
    const nameInputRefs = useRef([]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null); // Track which row is expanded
    const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [deleteConfirmation, setDeleteConfirmation] = useState({ open: false, entryId: null, type: "" });
    const [addNewBatchModal, setAddBatchtModalOpen] = useState({ open: false, inventoryId: null, productName: "" });
    const [filterOptions, setfilterOptions] = useState(false);
    const [editConfirmation, setEditConfirmation] = useState({ open: false, entryId: null });
    const [editModal, setEditModal] = useState({ open: false, entryId: null });
    const [vendorData, setVendorData] = useState([]);
    const [stockStatus, setStockStatus] = useState(true);
    const [searchFilters, setSearchFilters] = useState([]);

    const [pageLimit, setPageLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isFetching, setIsFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const observer = useRef();

    const handleIntersection = (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !loading && hasMoreData) {
            fetchInventoryData();
        }
    };
    
    useEffect(() => {
        fetchInventoryData();
        if(initialInventoryData.length<1)
        {
            setInitialInventoryData(inventoryData);
        }
        observer.current = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        });
        const targetNode = document.getElementById('lastItem');
        if (targetNode) {
            observer.current.observe(targetNode);
        }
        return () => {
            if (observer.current) {
            observer.current.disconnect();
            }
        };
    }, [inventoryData]);
    
    const categories = ['Select', 'Baby Care', 'Drinks & Supplements', 'Glocumeters & Test Strips', 'Women Care', 'Health Devices', 'Ayurveda', 'Personal Care', 'Sexual Wellness'];

    const fetchInventoryData = async () => {
        if (isFetching || !hasMoreData) {
            return;
        }
        setIsFetching(true);
        const user = JSON.parse(sessionStorage.getItem('user'));
        const userIdParam = `userId=${user._id}`;
        const productNameParam = searchTerm ? `&productName=${searchTerm}` : '';
        const pageParam = `&page=${page}`;
        const limitParam = `&limit=${pageLimit}`;
        const stockParam = `&stockParam=${stockStatus}`;

        try {
            const token = JSON.parse(sessionStorage.getItem('userToken'));
            const url = `${process.env.REACT_APP_API_STORE_URL}/inventory?${userIdParam}${productNameParam}${pageParam}${limitParam}${stockParam}`;
            const response = await axiosInstance.get(url);
      
            if (response.status == 200) {
                const data = response.data;
                setInventoryData((prevData) => [...prevData, ...data]);
                if (data.length < pageLimit) {
                    setHasMoreData(false);
                }
                setPage((prevPage) => prevPage + 1);
            } else {
                console.error('Failed to fetch inventory data');
            }
        } catch (error) {
            console.error('Error fetching inventory data:', error);
        } finally {
            setIsFetching(false);
        }
    };

    useEffect(() => {
        fetchVendorData();
    }, []);
    const fetchVendorData = async () => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        try {
          const token = JSON.parse(sessionStorage.getItem('userToken'));
          const url = `${process.env.REACT_APP_API_STORE_URL}/vendor?userId=${user._id}`;
          const response = await axiosInstance.get(url);
          if (response.status == 200) {
            const data = response.data;
            setVendorData(data);
          } else {
            console.error('Failed to fetch vendors data');
          }
        } catch (error) {
          console.error('Error fetching vendors data:', error);
        }
    };

    const setSearchTerm = async (e) => {
        const value = e.target.value;
        updateSearchTerm(value);
    
        if(value === ''){
            // setInventoryData(initialInventoryData);
            updateSearchTerm('');
            // return;
        }
        // if (value.trim() === '') {
        //     // setInventoryData(initialInventoryData);
        // } else 
        // {
          if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
          }
          const timer = setTimeout(async () => {
            setPage(0);
            setHasMoreData(true);
            setInventoryData([]);
            fetchInventoryData();
          }, 400);
          debounceTimer.current = timer;
        // }
    };

    const [editedItem, setEditedItem] = useState({
        inventoryId: "",
        isActive: "",
        batchData: [
            {
              batchId: "",
              batchNum: "",
              price: "",
              cost: "",
              discountPrice: "",
              expiryDate: "",
              vendor: "",
              stocks: "",
              onlineStocks: "",
              offlineStocks: "",
              isActive: "",
            }
        ]
    });

    const [newBatchItem, setNewBatchItem] = useState({
        inventoryId: "",
        price: "",
        cost: "",
        discountPrice: "",
        stocks: "",
        onlineStocks: "",
        offlineStocks: "",
        vendorId: "",
        expiryDate: "",
        isActive: false,
    });
      
    
    const handleBatchModalHide = () => {
        setAddBatchtModalOpen({open: false, inventoryId: null, productName: ""});
        setNewBatchItem({
            inventoryId: "",
            price: "",
            cost: "",
            discountPrice: "",
            stocks: "",
            onlineStocks: "",
            offlineStocks: "",
            vendorId: "",
            expiryDate: "",
            isActive: false,
        });
    }

    const handleAddBatchClick = (inventoryId, productName) => {
        setAddBatchtModalOpen({open: true, inventoryId: inventoryId, productName: productName});
    };  

    const handleAddNewBatch = async (inventoryId) => {
        newBatchItem.inventoryId = inventoryId;
        if(!newBatchItem.price || newBatchItem.price==0){
            toast.error('Product price is mandatory.', { position: 'top-right' });
            return;
        }
        if(!newBatchItem.cost || newBatchItem.cost==0){
            toast.error('Product cost is mandatory.', { position: 'top-right' });
            return;
        }
        if(!newBatchItem.discountPrice){
            newBatchItem.discountPrice = 0;
        }
        if(!newBatchItem.stocks){
            newBatchItem.stocks = 0;
        }
        if(!newBatchItem.onlineStocks){
            newBatchItem.onlineStocks = 0;
        }
        if(!newBatchItem.offlineStocks){
            newBatchItem.offlineStocks = 0;
        }
        if(!newBatchItem.expiryDate){
            toast.error('Expiry date cannot be blank.', { position: 'top-right' });
            return;
        }
        const formData = new FormData();
        for (const key in newBatchItem) {
            formData.append(key, newBatchItem[key]);
        }
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_STORE_URL}/create-batch`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            if (response.status === 200) 
            {
                handleBatchModalHide();    
                toast.success('Batch added successfully!', { position: 'top-right' });
                setPage(0);
                setHasMoreData(true);
                setInventoryData([]);
                fetchInventoryData();  
            }else{
                toast.error('Sorry! failed to insert batch data, please try again', { position: 'top-right' });
            }
        } catch (error) {
            toast.error('Sorry! something went wrong, please try again', { position: 'top-right' });
        }
    };

    const handleDeleteClick = (entryId, type) => {
        setDeleteConfirmation({ open: true, entryId, type });
    };
    const handleEditClick = (entryId) => {
        const selectedItem = inventoryData.find((item) => item.sku === entryId);
        setEditedItem({
            inventoryId: selectedItem.inventoryId,
            isActive: selectedItem.isActive,
            batchData: selectedItem.batchData.map(data => ({
              batchId: data.batchId,
              batchNum: data.batchNum,
              price: data.price,
              cost: data.cost,
              discountPrice: data.discountPrice,
              expiryDate: data.expiryDate,
              vendor: data.vendor,
              stocks: data.stocks,
              onlineStocks: data.onlineStocks,
              offlineStocks: data.offlineStocks,
              isActive: data.isActive,
            })),
        });
        setExpandedRow(entryId);
        setCurrentImageIndex(0);
        setEditConfirmation({ open: true, entryId });
    };

    const openEditModal = (entryId) => {
        setEditModal({ open: true, entryId });
        const selectedItem = inventoryData.find((item) => item.inventoryId === entryId);
        setEditedItem(selectedItem);
        if (selectedItem && selectedItem.batchData.length > 0) {
            setActiveBatch(selectedItem.batchData[0].batchId);
        } else {
            setActiveBatch(null);
        }
    };

    const hideEditModal = (sku) => {
        handleEditCancel(sku);
        setEditModal({ open: false, entryId: null });
    }

    const handleDeleteConfirmation = async () => {
        try {
            const response = await axiosInstance.delete(`${process.env.REACT_APP_API_STORE_URL}/delete-${deleteConfirmation.type}/${deleteConfirmation.entryId}`
            );
            if (response.status === 200) {
                setPage(0);
                setHasMoreData(true);
                setInventoryData([]);
                setDeleteConfirmation({ open: false, entryId: null , type: ""});
                if(deleteConfirmation.type == 'batch')
                {
                    toast.success('Batch deleted successfully!', { position: 'top-right' });
                }else{
                    toast.success('Inventory deleted successfully!', { position: 'top-right' });
                }
            }
        } catch (error) {
            if(deleteConfirmation.type == 'batch')
            {
                toast.error(error, { position: 'top-right' });
            }else{
                toast.error(error, { position: 'top-right' });
            }
        }
    };
    const handleDeleteCancel = () => {
        setDeleteConfirmation({ open: false, entryId: null, type: "" });
    };
    const handleEditCancel = (entryId) => {
        const selectedItem = inventoryData.find((item) => item.sku === entryId);
        setEditedItem({
            inventoryId: selectedItem.inventoryId,
            isActive: selectedItem.isActive,
            batchData: selectedItem.batchData.map(data => ({
              batchId: data.batchId,
              batchNum: data.batchNum,
              price: data.price,
              cost: data.cost,
              discountPrice: data.discountPrice,
              expiryDate: data.expiryDate,
              vendor: data.vendor,
              stocks: data.stocks,
              onlineStocks: data.onlineStocks,
              offlineStocks: data.offlineStocks,
              isActive: data.isActive,
            })),
        });
        // setEditedItem(selectedItem);
        setEditConfirmation({ open: false, entryId: null });
    };
    const updateInventory = async () => {
        setEditConfirmation({ open: false, entryId: null });
        const batchData = editedItem.batchData;
        const updateData = batchData.map(batch => ({
            batchId: batch.batchId,
            price: batch.price,
            cost: batch.cost,
            discountPrice: batch.discountPrice,
            stocks: batch.stocks,
            onlineUnits: batch.onlineStocks,
            offlineUnits: batch.offlineStocks,
            vendor: batch.vendor !== "undefined" ? batch.vendor : "",
            expiryDate: batch.expiryDate,
            isActive: batch.isActive === 'true',
        }));
        const updatedInventoryData = {
            inventoryId: editedItem.inventoryId,
            isActive: editedItem.isActive,
            batchData: updateData
        };
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_STORE_URL}/update-inventory`, updatedInventoryData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                setPage(0);
                setHasMoreData(true);
                setEditModal({ open: false, entryId: null });
                setInventoryData([]);
                toast.success('Inventory updated successfully!', { position: 'top-right' });
            } else {
                toast.success('Something went wrong! Please try again', { position: 'top-right' });
            }
        } catch (error) {
            console.error('There was a problem with the request');
        }
    }

    const handleAddProduct = async (newProduct) => {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const formData = new FormData();
        for (const key in newProduct) {
            if (newProduct.hasOwnProperty(key)) {
                if (key === 'images') {
                    newProduct.images.forEach((image) => {
                        formData.append('images', image);
                    });
                } else {
                    formData.append(key, newProduct[key]);
                }
            }
        }
        formData.append('partnerId',user._id);
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_STORE_URL}/create-inventory`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
          });
          if (response.status === 201) 
          {
            setAddProductModalOpen(false);    
            fetchInventoryData();  
            toast.success('Product added successfully!', { position: 'top-right' });
          }else{
            toast.error('Sorry! something went wrong, please try again', { position: 'top-right' });
          }
        } catch (error) {
          console.error(error);
          toast.error('Sorry! something went wrong, please try again', { position: 'top-right' });
        }
    };

    useEffect(() => {
        const checkScreenSize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };

        // Initial check and add event listener
        checkScreenSize();
        window.addEventListener('resize', checkScreenSize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const toggleRowExpansion = (rowId) => {
        const selectedItem = inventoryData.find((item) => item.sku === rowId);
        setEditedItem(selectedItem);
        if (expandedRow === rowId) {
            setExpandedRow(null);
            setCurrentImageIndex(0);
        } else {
            setExpandedRow(rowId);
            setCurrentImageIndex(0);
        }
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % inventoryData[0].images.length);
    };
    
    const previousImage = () => {
        setCurrentImageIndex((prevIndex) =>
          (prevIndex - 1 + inventoryData[expandedRow - 1].images.length) % inventoryData[expandedRow - 1].images.length
        );
    };

    const toggleStockStatus = () => {
        setStockStatus((prevStatus) => !prevStatus);
        setPage(0);
        setHasMoreData(true);
        setInventoryData([]);
        fetchInventoryData();
    };

    const updatePrimaryBatch = (sku, batchId, isChecked) => {
        if (isChecked) {
            const updatedBatchData = editedItem.batchData.map(batch => {
                if (batch.batchId === batchId) {
                    batch.isActive = "true"; // Set isActive to "true" for the matched batch
                } else {
                    batch.isActive = "false"; // Set isActive to "false" for other batches
                }
                return batch;
            });
            const updatedItem = { ...editedItem, batchData: updatedBatchData };
            document.querySelectorAll(`.chk_batch_inv_${sku}`).forEach((checkbox) => {
                checkbox.checked = false;
            });
            document.querySelector(`.chk_batch_${batchId}`).checked = true;
            setEditedItem(updatedItem);
        }
    };

    const updatePrimaryBatchBtn = (sku, batchId) => {
        const updatedBatchData = editedItem.batchData.map(batch => {
            if (batch.batchId === batchId) {
                batch.isActive = "true";
            } else {
                batch.isActive = "false";
            }
            return batch;
        });
        const updatedItem = { ...editedItem, batchData: updatedBatchData };
        setEditedItem(updatedItem);
    };

    const [activeBatch, setActiveBatch] = useState('');

    return (
        <div className="text-sm h-full">
            <div className="flex flex-col md:flex-row h-full text-gray-600">
                <div className="w-full md:w-10/12 h-full overflow-y-scroll">
                    <div className="p-4 md:py-8 md:px-6">
                        <div className="md:flex md:flex-row gap-2">
                            <div className="flex flex-row justify-between">
                                <h2 className="text-xl font-semibold">Inventory</h2>
                                <div className="md:hidden flex flex-row gap-2">
                                    <div className="border-2 rounded-md cursor-pointer px-2 py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200" onClick={() => setAddProductModalOpen(true)}>
                                        + Add Product
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:flex flex-row justify-end w-full gap-4">
                                <div className="w-6/12">
                                    <div className="border-2 rounded-full px-2 py-1 flex flex-row items-center gap-2">
                                        <label htmlFor="searchField" className="font-medium text-sm text-gray-400"><FaSearch /></label>
                                        <input id="searchField" type="text" value={searchTerm} onChange={(e) => setSearchTerm(e)} placeholder="Quick Search" className="px-2 text-sm w-full focus:outline-none focus:border-blue-500" />
                                    </div>
                                </div>
                                <div className="w-4/12 flex items-center gap-2 justify-end">
                                    <div className="border-2 rounded-md cursor-pointer px-2 text-lg py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200" onClick={() => setfilterOptions(true)}>
                                        <FaFilter />
                                    </div>
                                    <div className="border-2 rounded-md cursor-pointer px-2 w-28 text-sm py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200" onClick={() => setAddProductModalOpen(true)}>
                                        + Add Product
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isSmallScreen && (
                            <div className="md:hidden w-full flex flex-row gap-2 mt-2 mb-1">
                                <div className="border-2 w-full rounded-full px-2 py-1 flex flex-row items-center gap-2">
                                    <label htmlFor="searchField" className="font-medium text-sm text-gray-400"><FaSearch /></label>
                                    <input id="searchField" type="text" value={searchTerm} onChange={(e) => setSearchTerm(e)} placeholder="Quick Search" className="px-2 text-sm w-full focus:outline-none focus:border-blue-500" />
                                </div>
                                <div className="border-2 flex items-center rounded-md cursor-pointer px-2 py-1 text-blue-500 hover:text-white hover:bg-blue-500 duration-200" onClick={() => setfilterOptions(true)}>
                                    <FaFilter />
                                </div>
                            </div>
                        )}
                        <div
                            className={`flex flex-row gap-1 items-center max-w-max rounded-md cursor-pointer pb-2 md:py-2 duration-200 ${
                                stockStatus ? 'text-green-500' : 'text-red-500'
                            }`}
                            onClick={toggleStockStatus}
                        >
                            <span className="text-sm">{stockStatus ? 'IN STOCK' : 'OUT OF STOCK'}</span>
                            <BiSolidDownArrow className={`text-xs transform ${stockStatus ? 'rotate-180' : ''}`} />
                        </div>
                        {isSmallScreen ? (
                            <div className="">
                                {inventoryData.map((item,index) => (
                                    <div key={item.inventoryId} className="bg-white p-2 mb-2 shadow-md rounded-md" id={index === inventoryData.length - 1 ? 'lastItem' : null}>
                                        <div className="flex gap-1 items-center">
                                            <div className="w-2/12">
                                                <img src={item.images[0] != "" ? item.images[0] : defaultImage} className="w-12 h-12" />
                                            </div>
                                            <div className="w-10/12 flex flex-col gap-[2px]">
                                                <div className="w-full font-semibold">{item.name}</div>
                                                <div className="flex flex-row justify-between font-semibold bg-slate-50">
                                                    <div className="w-3/12 text-xs text-gray-500">MRP</div>
                                                    <div className="w-3/12 text-xs text-gray-500">SP</div>
                                                    <div className="w-2/12 text-xs text-gray-500">Stock</div>
                                                    <div className="w-2/12 text-xs text-gray-500">Site</div>
                                                    <div className="w-2/12 text-xs text-gray-500">Reserved</div>
                                                </div>
                                                {item.batchData.map((batch, i) => (
                                                    <div className="flex flex-row justify-between font-semibold bg-slate-50">
                                                        <div className="w-3/12">₹{batch.price}</div>
                                                        <div className="w-3/12">₹{batch.cost}</div>
                                                        <div className="w-2/12">{batch.stocks}</div>
                                                        <div className="w-2/12">{batch.onlineStocks}</div>
                                                        <div className="w-2/12 text-red-500">{batch.offlineStocks}</div>
                                                    </div>
                                                ))}
                                                <div className="flex gap-2 justify-end items-center">
                                                    <div className="border-2 rounded-md text-center text-[0.6rem] font-semibold cursor-pointer px-[0.15rem] text-white bg-green-500 hover:bg-green-600 duration-200" onClick={() => handleAddBatchClick(item.inventoryId, item.name)}>
                                                        + Add Batch
                                                    </div>
                                                    <FaEdit className="text-gray-500 cursor-pointer hover:text-blue-500" title="Edit" onClick={() => openEditModal(item.inventoryId)}/>
                                                    <span>|</span>
                                                    <FaTrashAlt className="text-red-500 cursor-pointer hover:text-red-600" title="Remove" onClick={() => handleDeleteClick(item.inventoryId, 'inventory')} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {loading && <p>Loading...</p>}
                            </div>
                        ) : (
                            <div className="w-full text-sm">
                                <div className="bg-white p-2 mb-[.1rem] shadow-md rounded-md">
                                    <div className="flex items-center font-semibold text-gray-400">
                                        <div className="w-11 pl-2 px-2">#</div>
                                        <div className="w-full flex items-center">
                                            <div className="w-1/12 px-2"></div>
                                            <span className="w-10/12 flex">
                                                <div className="w-4/12 pr-2">Name</div>
                                                <div className="w-8/12 flex">
                                                    <div className="w-2/12 pl-6 pr-2 text-center">BATCH</div>
                                                    <div className="w-2/12 pl-6 pr-2 text-center">MRP</div>
                                                    <div className="w-2/12 pl-6 pr-2 text-center">SP</div>
                                                    <div className="w-2/12 pl-6 pr-2 text-center">Stock</div>
                                                    <div className="w-2/12 pl-6 pr-2 text-center">Site</div>
                                                    <div className="w-2/12 px-6 text-center">Reserved</div>
                                                </div>
                                            </span>
                                            <div className="w-1/12 pl-6 pr-2 flex items-center text-gray-500 gap-[6px]">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {inventoryData.map((item, index) => (
                                    <div key={item.sku} className="bg-white mb-2 shadow-md rounded-md">
                                        <div className="flex items-center px-2 py-2">
                                            <div className="w-11 px-2 text-gray-500 font-semibold">
                                                {index+1}.
                                            </div>
                                            <div className="w-full flex items-center">
                                                <div className="w-1/12">
                                                    <div className="w-9/12 rounded-lg border-2 py-1 flex items-center justify-center">
                                                        <img src={item.images[0] != "" ? item.images[0] : defaultImage} className="w-8 h-8" />
                                                    </div>
                                                </div>
                                                <div className="w-10/12 flex cursor-pointer" onClick={() => toggleRowExpansion(item.sku)}>
                                                    <div className="w-4/12 pr-2">{item.name}</div>
                                                    <div className="w-8/12 flex flex-col gap-1 text-xs">
                                                        {item.batchData.map((batch, i) => (
                                                            <div className="w-full flex flex-row">
                                                                <div className="w-2/12 pl-6 pr-2 text-center text-gray-500 font-semibold">#{String(batch.batchNum).padStart(4, '0')}</div>
                                                                <div className="w-2/12 pl-6 pr-2 text-center text-gray-500 font-semibold">₹{batch.price}</div>
                                                                <div className="w-2/12 pl-6 pr-2 text-center text-gray-500 font-semibold">₹{parseFloat(batch.price) - parseFloat(batch.discountPrice)}</div>
                                                                <div className="w-2/12 pl-6 pr-2 text-center text-gray-500 font-semibold">{parseInt(batch.stocks)}</div>
                                                                <div className="w-2/12 pl-6 pr-2 text-center text-green-500 font-semibold">{batch.onlineStocks}</div>
                                                                <div className="w-2/12 px-6 text-center text-red-500 font-semibold">{batch.offlineStocks}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="w-1/12 pl-6 pr-2 flex items-center text-gray-500 gap-[6px]">
                                                    <FaEdit className="text-gray-500 cursor-pointer hover:text-blue-500" title="Edit" onClick={() => handleEditClick(item.sku)}/>
                                                    <span>|</span>
                                                    <FaTrashAlt className="text-red-500 cursor-pointer hover:text-red-600" title="Remove" onClick={() => handleDeleteClick(item.inventoryId, 'inventory')} />
                                                </div>
                                            </div>
                                        </div>
                                        {expandedRow === item.sku && (
                                            <div className="flex items-center border-t-2 mb-2 p-2 pt-4 pb-4">
                                                <div className="w-11 px-2"></div>
                                                <div className="flex flex-col">
                                                    <div className="w-full flex gap-2">
                                                        <div className="w-2/12 flex flex-col items-center">
                                                            <div className="flex items-center justify-center rounded-xl border-2 w-full h-36 p-4">
                                                                <img src={item.images[0] != "" ? item.images[currentImageIndex] : defaultImage} alt={`Image ${currentImageIndex + 1}`} />
                                                            </div>
                                                            {item.images[0] !="" && (
                                                            <div className="w-full pt-1">
                                                                {item.images.length > 1 && (
                                                                    <div className="flex flex-row items-center justify-center gap-2">
                                                                        <div>{`${currentImageIndex + 1} of ${item.images.length} `}</div>
                                                                        <FaArrowRight className="cursor-pointer" onClick={() => { nextImage(); }} />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            )}
                                                        </div>
                                                        <div className="w-10/12 flex flex-col gap-2">
                                                            <div className="flex flex-row gap-2 items-baseline text-xs text-justify pt-2  text-gray-400">
                                                                <label className="block text-gray-400 font-medium text-sm">Composition:</label>
                                                                <span>{item.composition}</span>
                                                            </div>
                                                            <div className="flex flex-row gap-2">
                                                                <div className="w-6/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Brand</label>
                                                                    <input type="text" className="w-full border-b-2 border-gray-400" value={item.brand} disabled readOnly/>
                                                                </div>
                                                                <div className="w-4/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Availability</label>
                                                                    <select className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={editedItem.isActive} disabled={!editConfirmation.open} onChange={(e) => setEditedItem({ ...editedItem, isActive: e.target.value })} >
                                                                        <option value="">Select</option>
                                                                        <option value="true">Online</option>
                                                                        <option value="false">Offline</option>
                                                                    </select>
                                                                </div>
                                                                {/* {editConfirmation.open && ( */}
                                                                    <div className="w-2/12 flex justify-end items-end">
                                                                        <div className="border-2 rounded-md w-full text-center text-xs font-semibold cursor-pointer px-1 py-2 text-white bg-green-500 hover:bg-green-600 duration-200" onClick={() => handleAddBatchClick(item.inventoryId, item.name)}>
                                                                            + Add Batch
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="w-3/12 flex justify-end">
                                                                        <span className="w-full flex flex-row justify-end gap-4 pt-5">
                                                                            <button className="w-full border-2 flex items-center justify-center gap-[2px] rounded-md px-2 py-1 bg-green-500 text-white font-semibold hover:bg-green-600 duration-200" onClick={updateInventory}>Save</button>
                                                                            <button className="w-full border-2 flex items-center justify-center gap-[2px] rounded-md px-2 py-1 bg-gray-400 text-white font-semibold hover:bg-gray-500 duration-200" onClick={() => handleEditCancel(item.sku)}>Cancel</button>
                                                                        </span>
                                                                    </div> */}
                                                                {/* )} */}
                                                            </div>
                                                            <div className="flex flex-row gap-1">
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">MRP</label>
                                                                </div>
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Cost</label>
                                                                </div>
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Discount</label>
                                                                </div>
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Stock</label>
                                                                </div>
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Site</label>
                                                                </div>
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Reserved</label>
                                                                </div>
                                                                <div className="w-2/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Expiry</label>
                                                                </div>
                                                                <div className="w-2/12">
                                                                    <label className="block text-gray-400 font-medium text-sm">Vendor</label>
                                                                </div>
                                                                <div className="w-1/12">
                                                                    <label className="block text-gray-400 text-center font-medium text-sm">Primary</label>
                                                                </div>
                                                                {/* {editConfirmation.open &&  */}
                                                                    <div className="w-1/12">
                                                                    </div>
                                                                {/* } */}
                                                            </div>
                                                            {editedItem.batchData.map((batch, i) => (
                                                                <div className="flex flex-row gap-1">
                                                                    {/* <div className="w-1/12">
                                                                        <input type="number" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.batchNum} disabled readOnly />
                                                                    </div> */}
                                                                    <div className="w-1/12">
                                                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.price} disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].price = e.target.value; return updatedItem; }); }} />
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.cost} disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].cost = e.target.value; return updatedItem; }); }} />
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.discountPrice} disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].discountPrice = e.target.value; return updatedItem; }); }} />
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <input type="number" min={0} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.stocks} disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].stocks = e.target.value; return updatedItem; }); }}/>
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <input type="number" min={0} className="w-full border-b-2 text-green-500 font-semibold border-gray-400 focus:outline-none focus:border-blue-500" value={batch.onlineStocks} disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].onlineStocks = e.target.value; return updatedItem; }); }} />
                                                                    </div>
                                                                    <div className="w-1/12">
                                                                        <input type="number" min={0} className="w-full border-b-2 border-gray-400 text-red-500 font-medium focus:outline-none focus:border-blue-500" value={batch.offlineStocks} disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].offlineStocks = e.target.value; return updatedItem; }); }} />
                                                                    </div>
                                                                    <div className="w-2/12">
                                                                    <DatePicker selected={batch.expiryDate}
                                                                        onChange={(date) => {
                                                                            const formattedDate = date.toISOString().slice(0, 7);
                                                                            setEditedItem(prevState => {
                                                                                const updatedItem = { ...prevState };
                                                                                updatedItem.batchData[i].expiryDate = formattedDate;
                                                                                return updatedItem;
                                                                            });
                                                                        }} 
                                                                        dateFormat="MM/yyyy" 
                                                                        showMonthYearPicker 
                                                                        className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 text-sm" 
                                                                        readOnly={!editConfirmation.open} 
                                                                    />                                                                        {/* <input type="month" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 text-sm" value={batch.expiryDate} readOnly={!editConfirmation.open} onChange={(e) => setEditedItem({ ...editedItem, expiryDate: e.target.value })} /> */}
                                                                    </div>
                                                                    <div className="w-2/12">
                                                                        <select value={batch.vendor} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" disabled={!editConfirmation.open} onChange={(e) => { setEditedItem(prevState => { const updatedItem = { ...prevState }; updatedItem.batchData[i].vendor = e.target.value; return updatedItem; }); }}>
                                                                            <option>Select</option>
                                                                            {vendorData.map((vendor) => (
                                                                                <option key={vendor._id} value={vendor._id}>
                                                                                    {vendor.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    <div className="w-1/12 flex justify-center items-center">
                                                                        <input type="checkbox" className={`mx-2 chk_batch_inv_${item.sku} chk_batch_${batch.batchId} ${!editConfirmation.open && 'pointer-events-none' }`} defaultChecked={batch.isActive !== "false"} readOnly={!editConfirmation.open}  onChange={(e) => { e.target.checked = true; updatePrimaryBatch(item.sku, batch.batchId, e.target.checked)} } />                                                                    </div>
                                                                    {/* {editConfirmation.open && */}
                                                                        <div className="w-1/12 flex justify-center items-center">
                                                                            <FaTrashAlt className="text-red-500 cursor-pointer hover:text-red-600" title="Remove" onClick={() => { batch.isActive !== "true" ? handleDeleteClick(batch.batchId,'batch') : toast.error('Cannot delete primary batch. Please change the primary batch and try again', { position: 'top-right' }); }} />
                                                                        </div>
                                                                    {/* } */}
                                                                </div>
                                                            ))}
                                                            {editConfirmation.open && (
                                                            <div className="w-full flex justify-end">
                                                                <div className="w-3/12 flex justify-end">
                                                                    <span className="w-full flex flex-row justify-end gap-4 pt-3">
                                                                        <button className="w-full border-2 flex items-center justify-center gap-[2px] rounded-md px-2 py-1 bg-green-500 text-white font-semibold hover:bg-green-600 duration-200" onClick={updateInventory}>Save</button>
                                                                        <button className="w-full border-2 flex items-center justify-center gap-[2px] rounded-md px-2 py-1 bg-gray-400 text-white font-semibold hover:bg-gray-500 duration-200" onClick={() => handleEditCancel(item.sku)}>Cancel</button>
                                                                    </span>
                                                                </div>
                                                                {/* <div className="w-11/12  flex justify-end items-center">
                                                                    <div className="border-2 rounded-md text-xs cursor-pointer px-1 py-1 text-white bg-green-500 hover:bg-green-600 duration-200" onClick={() => handleAddBatchClick(item.inventoryId, item.name)}>
                                                                        + Add Batch
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {/* <div className="flex flex-row gap-2 items-baseline text-xs text-justify py-2 text-gray-400">
                                                        <label className="block text-gray-400 font-medium text-sm">Composition:</label>
                                                        <span>{item.composition}</span>
                                                    </div> */}
                                                    {/* <div className="text-xs text-justify py-2 text-gray-400">
                                                        <label className="block text-gray-400 font-medium text-sm">Description:</label>
                                                        {item.description}
                                                    </div> */}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        <AddProductModal
                        isOpen={isAddProductModalOpen}
                        onClose={() => setAddProductModalOpen(false)}
                        onAddProduct={handleAddProduct}
                        />
                    </div>
                </div>
                {!isSmallScreen ? 
                (
                    <div className="w-full h-full flex flex-col justify-between border-2 border-l-[#c9d7ff] md:w-2/12 bg-[#f9f8ff] p-4">
                        <div className="text-gray-400 font-semibold flex flex-col gap-2 pt-6 justify-between h-full">
                            <div className="flex flex-col gap-6">
                                <h3 className="text-gray-400 text-xs">OVERVIEW</h3>
                                <div>
                                    <div className="text-sm">Total SKUs</div>
                                    <div className="text-xl text-gray-500">{inventoryData.length}</div>
                                </div>
                                <div>
                                    <div className="text-sm">Total Units</div>
                                    <div className="text-xl text-gray-500">170</div>
                                </div>
                                <div>
                                    <div className="text-sm">Stock Issues</div>
                                    <div className="text-xl text-red-500">2</div>
                                </div>
                            </div>
                        </div>
                        <div className="md:w-full flex justify-start p-4">
                        </div>
                    </div>
                ): (
                    <div className="w-full bg-[#f9f8ff] text-gray-500 p-2">
                        {/* <h3 className="text-gray-400">OVERVIEW</h3> */}
                        <div className="flex flex-row justify-between font-semibold text-xs">
                            <div className="flex items-center gap-2">
                                <div className="text-sm text-gray-500">Total SKUs:</div>
                                <div className="text-base text-gray-500">{inventoryData.length}</div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm">Total Units:</div>
                                <div className="text-base text-gray-500">170</div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="text-sm">Stock Issues:</div>
                                <div className="text-base text-red-500">2</div>
                            </div>
                        </div>
                    </div>
                )}
                <ToastContainer />
                {addNewBatchModal.open && (
                    <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                        <div className="modal-content bg-white rounded-md w-10/12 md:w-2/5">
                            <div className="header p-4">
                                <h2 className="text-xl font-semibold">Add New Batch</h2>
                                <hr className="" />
                            </div>
                            <div className="px-4 flex flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                                <div className="flex flex-col md:flex-row md:items-center gap-2">
                                    <label className="md:w-3/12">Product Name:</label>
                                    <input type="text" value={addNewBatchModal.productName} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" readOnly disabled />                    
                                </div>
                                <div className="w-full flex gap-6 mt-4">
                                    <div className="w-1/3">
                                        <label className="block text-gray-400 font-semibold text-sm">MRP</label>
                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.price}  onChange={(e) => setNewBatchItem({ ...newBatchItem, price: e.target.value })} />
                                    </div>
                                    <div className="w-1/3">
                                        <label className="block text-gray-400 font-semibold text-sm">Cost</label>
                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.cost}  onChange={(e) => setNewBatchItem({ ...newBatchItem, cost: e.target.value })} />
                                    </div>
                                    <div className="w-1/3">
                                        <label className="block text-gray-400 font-semibold text-sm">Discount</label>
                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.discountPrice}  onChange={(e) => setNewBatchItem({ ...newBatchItem, discountPrice: e.target.value })} />
                                    </div>
                                </div>
                                <div className="w-full flex gap-6 mt-4">
                                    <div className="w-1/3">
                                        <label className="block text-gray-400 font-semibold text-sm">Stock</label>
                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.stocks}  onChange={(e) => setNewBatchItem({ ...newBatchItem, stocks: e.target.value })} />
                                    </div>
                                    <div className="w-1/3">
                                        <label className="block text-gray-400 font-semibold text-sm">Site</label>
                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.onlineStocks}  onChange={(e) => setNewBatchItem({ ...newBatchItem, onlineStocks: e.target.value })} />
                                    </div>
                                    <div className="w-1/3">
                                        <label className="block text-gray-400 font-semibold text-sm">Reserved</label>
                                        <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.offlineStocks}  onChange={(e) => setNewBatchItem({ ...newBatchItem, offlineStocks: e.target.value })} />
                                    </div>
                                </div>
                                <div className="w-full md:flex gap-6 mt-4">
                                    <div className="md:w-1/2">
                                        <label className="block text-gray-400 font-semibold text-sm">Expiry Date</label>
                                        <input type="month" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.expiryDate}  onChange={(e) => setNewBatchItem({ ...newBatchItem, expiryDate: e.target.value })} />
                                    </div>
                                    <div className="md:w-1/2">
                                        <label className="block text-gray-400 font-semibold text-sm">Vendor</label>
                                        <select className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={newBatchItem.vendorId} onChange={(e) => setNewBatchItem({ ...newBatchItem, vendorId: e.target.value })} >
                                            <option value="">Select</option>
                                            {vendorData.map((vendor) => (
                                                <option key={vendor._id} value={vendor._id}>
                                                    {vendor.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="modal-footer flex pb-4 justify-end gap-2">
                                    <button onClick={handleBatchModalHide} className="text-gray-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                                    <button onClick={() => handleAddNewBatch(addNewBatchModal.inventoryId)} className="text-green-500 bg-white border px-5 py-1 cursor-pointer hover:text-white hover:bg-green-500 rounded-sm border-green-500">Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isSmallScreen && editModal.open && (
                    <div className={`fixed top-0 left-0 bg-gray-600/70 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                        <div className="modal-content bg-white rounded-sm w-11/12 p-4">
                            <div className="flex justify-start">
                              <FaChevronLeft className="text-gray-300 text-xl" onClick={() => hideEditModal(editedItem.inventoryId)} />  
                            </div>
                            <div className="w-full flex flex-col gap-1 items-center">
                                <div className="rounded-full shadow-md border-2 w-28 h-28 p-2">
                                <img src={editedItem.images[0] != "" ? editedItem.images[currentImageIndex] : defaultImage} alt={`Image ${currentImageIndex + 1}`} className="p-3" />
                                </div>
                                <div className="font-semibold text-lg text-center">{editedItem.name}</div>
                            </div>
                            <div className="w-full flex gap-6 mt-4">
                                <div className="w-full">
                                    <label className="block text-gray-400 font-semibold text-sm">Brand</label>
                                    <input type="text" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={editedItem.brand} readOnly disabled />
                                </div>
                            </div>
                            <div className="w-full flex gap-6 mt-4">
                                <div className="w-full">
                                    <label className="block text-gray-400 font-semibold text-sm">Availability</label>
                                    <select className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={editedItem.isActive} onChange={(e) => setEditedItem({ ...editedItem, isActive: e.target.value })} >
                                        <option value="">Select</option>
                                        <option value="true">Online</option>
                                        <option value="false">Offline</option>
                                    </select>
                                </div>
                            </div>
                            <div className="w-full flex gap-2 mt-1 p-1 bg-gray-100 rounded-t-md">
                                <div className="flex items-center font-semibold">Batch:</div>
                                {editedItem.batchData.map((batch, i) => (
                                    <div key={i} className={`px-2 py-1 text-sm cursor-pointer border rounded-md ${activeBatch === batch.batchId ? 'bg-blue-500 text-white' : 'bg-white border-gray-200 text-blue-500'}`} onClick={() => setActiveBatch(batch.batchId)}>
                                    #{String(batch.batchNum).padStart(4, '0')}
                                    </div>
                                ))}
                            </div>
                            {editedItem.batchData.map((batch, i) => (
                                <div key={i} className={`${activeBatch === batch.batchId ? '' : 'hidden'} rounded-b-md shadow-sm`}>
                                    <div className="text-gray-500">
                                        <div className="w-full flex gap-6 p-1">
                                            <div className="w-1/3">
                                                <label className="block text-gray-400 font-semibold text-sm">MRP</label>
                                                <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.price} onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, price: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} />
                                            </div>
                                            <div className="w-1/3">
                                                <label className="block text-gray-400 font-semibold text-sm">Cost</label>
                                                <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.cost} onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, cost: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} />
                                            </div>
                                            <div className="w-1/3">
                                                <label className="block text-gray-400 font-semibold text-sm">Discount</label>
                                                <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.discountPrice} onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, discountPrice: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} />
                                            </div>
                                        </div>
                                        <div className="w-full flex gap-6 py-2 px-1">
                                            <div className="w-1/3">
                                                <label className="block text-gray-400 font-semibold text-sm">Stock</label>
                                                <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.stocks} onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, stocks: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} />
                                            </div>
                                            <div className="w-1/3">
                                                <label className="block text-gray-400 font-semibold text-sm">Site</label>
                                                <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.onlineStocks} onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, onlineStocks: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} />
                                            </div>
                                            <div className="w-1/3">
                                                <label className="block text-gray-400 font-semibold text-sm">Reserved</label>
                                                <input type="number" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.offlineStocks} onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, offlineStocks: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} />
                                            </div>
                                        </div>
                                        <div className="w-full flex gap-6 py-2 px-1">
                                            <div className="w-1/2">
                                                <label className="block text-gray-400 font-semibold text-sm">Expiry Date</label>
                                                <DatePicker 
                                                    selected={batch.expiryDate}
                                                    onChange={(date) => {
                                                        const formattedDate = date.toISOString().slice(0, 7);
                                                        const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, expiryDate: formattedDate } : batchItem);
                                                        setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData }));
                                                    }} 
                                                    dateFormat="MM/yyyy" 
                                                    showMonthYearPicker 
                                                    className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500 text-sm"
                                                />
                                                {/* <input type="month" step="0.01" className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" value={batch.expiryDate}  onChange={(e) => setEditedItem({ ...editedItem, expiryDate: e.target.value })} /> */}
                                            </div>
                                            <div className="w-1/2">
                                                <label className="block text-gray-400 font-semibold text-sm">Vendor</label>
                                                <select value={batch.vendor} className="w-full border-b-2 border-gray-400 focus:outline-none focus:border-blue-500" onChange={(e) => { const updatedBatchData = editedItem.batchData.map((batchItem, index) => index === i ? { ...batchItem, vendor: e.target.value } : batchItem); setEditedItem(prevState => ({ ...prevState, batchData: updatedBatchData })); }} >
                                                    <option>Select</option>
                                                    {vendorData.map((vendor) => (
                                                        <option key={vendor._id} value={vendor._id}>
                                                            {vendor.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="w-full flex gap-2 py-2 px-2 justify-between">
                                            <div className={`w-1/2 px-1 py-1 text-center shadow-sm border rounded-sm border-blue-500 ${batch.isActive === "true" ? "bg-blue-500 text-white" : "text-blue-500"}`} onClick={(e) => batch.isActive !== "true" && updatePrimaryBatchBtn(editedItem.sku, batch.batchId)}>{batch.isActive === "true" ? "Marked as Primary" : "Mark as Primary"}</div>
                                            <div className="w-1/2 px-1 py-1 text-center shadow-sm border rounded-sm border-red-500 text-red-500" onClick={() => { batch.isActive !== "true" ? handleDeleteClick(batch.batchId,'batch') : toast.error('Cannot delete primary batch. Please change the primary batch and try again', { position: 'top-right' }); }}>Remove Batch</div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="flex flex-row gap-6 pt-5 text-base font-semibold">
                                <button className="w-full rounded-sm px-2 py-2 bg-gray-400 text-white font-semibold hover:bg-gray-500 duration-200" onClick={() => hideEditModal(editedItem.sku)}>Cancel</button>
                                <button className="w-full rounded-sm px-2 py-2 bg-green-500 text-white font-semibold hover:bg-green-600 duration-200" onClick={updateInventory}>Save</button>
                            </div>
                        </div>
                    </div>
                )}
                {filterOptions && (
                    <div className={`fixed top-0 left-0 bg-gray-600/60 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                        <div className="modal-content bg-white rounded-sm w-screen h-screen p-4">
                            <div className="flex justify-start">
                            <FaChevronLeft className="text-gray-300 text-xl" onClick={() => setfilterOptions(false)} />  
                            </div>
                            <div className="text-gray-500">
                                <div className="w-full flex flex-col gap-1 items-center">
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {deleteConfirmation.open && (
                    <div className={`fixed top-0 left-0 bg-gray-600/30 w-[100%] h-[100%] flex items-center justify-center z-50 modal open`}>
                        <div className="modal-content bg-white rounded-md w-10/12 md:w-2/5">
                            <div className="header p-4">
                                <h2 className="text-2xl font-semibold"><FaExclamationTriangle className="text-red-500 inline-block text-2xl mb-2 mr-2" />Remove {deleteConfirmation.type == "batch" ? "Batch" : "Inventory"}</h2>
                                <hr className="" />
                            </div>
                            <div className="px-4 flex flex-col gap-4 max-h-[32rem] md:max-h-[28rem] overflow-hidden overflow-y-scroll md:overflow-auto md:overflow-y-auto">
                                <p className="text-gray-500">
                                    Are you sure you want to remove this item?
                                </p>
                                <div className="modal-footer flex pb-4 justify-end gap-2">
                                    <button onClick={handleDeleteConfirmation} className="text-red-500 bg-white border px-5 py-1 cursor-pointer hover:text-white hover:bg-red-500 rounded-sm border-red-500">Remove</button>
                                    <button onClick={handleDeleteCancel} className="text-gray-500 bg-white border px-2 rounded-sm cursor-pointer hover:text-white hover:bg-gray-500 py-1 border-gray-500">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Inventory;
