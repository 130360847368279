// utils/auth.js
import axios from 'axios';

export const refreshAccessToken = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/refresh-token`, {}, { withCredentials: true });
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        sessionStorage.setItem('currentStep', JSON.stringify(response.data.currentStep));
        sessionStorage.setItem('partnerDetails', JSON.stringify(response.data.partnerDetails));
        sessionStorage.setItem('appliedForPartner', JSON.stringify(response.data.appliedForPartner));
        sessionStorage.setItem('userToken', JSON.stringify(response.data.accessToken));
        const userStatus = response.data.user.status;
        if (userStatus === "Registered" || userStatus === "Pending Approval") {
            window.location.href = response.data.appliedForPartner ? '/partner-register' : '/registration';
        } else if (userStatus === "Completed") {
            window.location.href = '/dashboard';
        }
    } catch (error) {
        console.error('Failed to refresh access token', error);
        window.location.href = '/';
    }
};
