import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBg from '../img/bg_new_2.jpg';
// import logoColor from '../img/logoNameColor.png';
import logoWhite from '../img/logoNameWhite.png';
import { FaStore, FaUser, FaCreditCard, FaCheck, FaUserCircle } from 'react-icons/fa';
import { MdArrowDropDown } from 'react-icons/md';
import StoreSection from './partnerRegistration/StoreSection';
import PersonalSection from './partnerRegistration/PersonalSection';
import BillingSection from './partnerRegistration/BillingSection';
import VerifySection from './partnerRegistration/VerifySection';
import axiosInstance from '../utils/axios';

const RegisterPartner = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [userName, setUserName ] = useState('');
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [storeData, setStoreData] = useState({
        storeName: '',
        drugLicNum: '',
    });
    const [personalData, setPersonalData] = useState({
        fullName: '',
        panNumber: '',
        gstin: '',
        address: '',
        state: '',
        zipCode: '',
        city: '',
        landmark: '',
    });
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        const currentStep = parseInt(JSON.parse(sessionStorage.getItem('currentStep')));
        const userLoggedIn = JSON.parse(sessionStorage.getItem('user'));
        setUserName(userLoggedIn.fullName);
        setStep(currentStep);
    }, []);

    const headerStyle = {
        backgroundImage: `url(${HeaderBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '100%',
    };
    const handleLogout = () => {
        sessionStorage.removeItem('user');
        sessionStorage.clear();
        navigate('/');
      };

    const steps = [
        { icon: <FaStore />, title: 'Store', description: 'Add store details' },
        { icon: <FaUser />, title: 'Personal', description: 'Add personal information' },
        { icon: <FaCreditCard />, title: 'Payment', description: 'Add billing & payment' },
        { icon: <FaCheck />, title: 'Verify', description: 'Verify your information' },
    ];

    const nextStep = () => {
        let validationErrors = '';
        if(step===1)
        {
            validationErrors = validateStep(step, storeData);
        }else if(step===2)
        {
            validationErrors = validateStep(step, personalData);
        }
        if (Object.keys(validationErrors).length === 0) {
            setValidationErrors({});
            setStep(step + 1);
            const updatedStep = step + 1;
            sessionStorage.setItem('currentStep', JSON.stringify(updatedStep));
        } else {
            setValidationErrors(validationErrors);
        }
    };

    const prevStep = () => {
        setStep(step - 1);
        const updatedStep = step - 1;
        sessionStorage.setItem('currentStep', JSON.stringify(updatedStep));
    };

    const saveData = async (section, data) => {
        let url = '';
        if (section === 'store') {
          url = `${process.env.REACT_APP_API_STORE_URL}/store-register`;
        }
        else if (section === 'personal') {
            url = `${process.env.REACT_APP_API_STORE_URL}/personal-register`;
        }
        const user = JSON.parse(sessionStorage.getItem('user'));
        const formData = new FormData();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
          }
        }
        formData.append('partnerId',user._id);
        try {
            const response = await axiosInstance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
          });
          if (response.status === 201) {
            // Handle success
          }
        } catch (error) {
          console.error(error);
        }
      };      

    const validateStep = (stepNumber, data) => {
        switch (stepNumber) {
            case 1:
                const storeValidationResponse = isStoreDataValid(data);
                if(Object.keys(storeValidationResponse).length === 0){
                    const response = saveData('store',data);
                }
                return storeValidationResponse;
            case 2:
                const personalValidationResponse = isPersonalDataValid(data);
                if(Object.keys(personalValidationResponse).length === 0){
                    const response = saveData('personal',data);
                }
                return personalValidationResponse;
            case 3:
                return isBillingDataValid(data);
            case 4:
                return isVerificationDataValid(data);
            default:
                return {};
        }
    };

    const isStoreDataValid = (data) => {
        const errors = {};
        if (data.storeName.trim() === '') {
            errors.storeName = 'Store Name is required';
        }
        if (data.drugLicNum.trim() === '') {
            errors.drugLicNum = 'Drug License Number is required';
        }
        if (!data.selectedFile) {
            errors.selectedFile = 'Please upload a file';
        }
        return Object.keys(errors).length === 0 ? {} : errors;
    };

    const isPersonalDataValid = (data) => {
        const errors = {};
        if (data.fullName.trim() === '') {
            errors.fullName = 'Full Name is required';
        }
        if (data.panNumber.trim() === '') {
            errors.panNumber = 'PAN Number is required';
        }
        if (!data.selectedFile) {
            errors.selectedFile = 'Please upload a file';
        }
        if (data.gstin.trim() === '') {
            errors.gstin = 'GSTIN is required';
        }
        if (data.address.trim() === '') {
            errors.address = 'Address is required';
        }
        if (data.state.trim() === '') {
            errors.state = 'State is required';
        }
        if (data.zipCode.trim() === '') {
            errors.zipCode = 'Zip Code is required';
        }
        if (data.city.trim() === '') {
            errors.city = 'City is required';
        }
        return Object.keys(errors).length === 0 ? {} : errors;
    };

    const isBillingDataValid = (data) => {
        return true;
    };

    const isVerificationDataValid = (data) => {
        return true;
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div style={headerStyle}>
                <header className="text-white h-16 flex items-center justify-between px-8">
                    {/* <div className="flex items-center">
                        <h1 className="text-2xl font-semibold mr-2">
                            <img src={logoColor} alt="MediSeen" className="w-32" />
                        </h1>
                        <p className="text-sm text-gray-400 relative top-1">Partners</p>
                    </div> */}
                    <div className="flex items-center">
                        <h1 className="text-2xl font-semibold mr-2">
                        <img src={logoWhite} alt="MediSeen" className="md:w-32 w-28" />
                        </h1>
                        <p className="text-sm text-gray-300 relative top-1 hidden md:block">Partners</p>
                    </div>
                    <div className="font-semibold text-sm md:text-base">
                        <FaUserCircle className="inline-block text-3xl md:text-4xl" />{' '}Hello, {userName}{''}
                        {/* <MdArrowDropDown className="inline-block text-2xl" /> */}
                        <div className="relative group inline-block">
                            {showUserDropdown && (
                                <div className="absolute right-0 mt-6 w-auto bg-white text-blue-500 border rounded-lg shadow-lg">
                                {/* Add content for the user dropdown here, e.g., a Logout button */}
                                <button onClick={handleLogout} className="block w-full py-2 px-4 text-base text-left rounded-lg hover:bg-gray-100">
                                    Logout
                                </button>
                                </div>
                            )}
                            <MdArrowDropDown
                                className="inline-block text-2xl cursor-pointer"
                                onClick={() => setShowUserDropdown(!showUserDropdown)}
                            />
                        </div>
                    </div>
                </header>
                <div className="mt-12 mb-12">
                    <div className="md:w-3/5 w-4/5 mx-auto bg-white mt-[-20px] rounded p-4 shadow-md">
                        <div className="flex md:flex-row flex-col justify-between md:mx-4">
                            <div className="flex md:flex-col justify-evenly md:justify-normal md:gap-16 py-6 md:py-16 md:pr-4 md:border-r border-gray-200">
                                {steps.map((s, index) => (
                                    <div key={index} className={`text-end ${step >= index + 1 ? 'text-blue-500' : 'text-gray-400'}`}>
                                        <div className="flex justify-end gap-x-4">
                                            <div className="hidden md:block text-sm h-12">
                                                <p className="font-semibold">{s.title}</p>
                                                <p className="text-xs text-gray-500">{s.description}</p>
                                            </div>
                                            <div className="flex flex-col min-w-[72px] md:min-w-0 md:justify-center items-center">
                                                <p className="md:hidden text-sm font-semibold pb-1 md:p-0">{s.title}</p>
                                                <div
                                                    className={`h-10 md:h-12 w-10 md:w-12 flex items-center justify-center rounded-full ${
                                                        step >= index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-400 text-gray-100'
                                                    }`}
                                                >
                                                    {s.icon}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="md:pl-4 w-full" style={{ height: 'inherit' }}>
                                <div className="text-center relative h-full">
                                    {step === 1 && <StoreSection storeData={storeData} setStoreData={setStoreData} validationErrors={validationErrors} />}
                                    {step === 2 && <PersonalSection personalData={personalData} setPersonalData={setPersonalData} validationErrors={validationErrors} />}
                                    {step === 3 && <BillingSection nextStep={nextStep} />}
                                    {step === 4 && <VerifySection />}
                                    <div className={`flex w-auto ${step === 3 ? 'justify-center' : 'justify-end'} mt-4`}>
                                        {step > 1 && step < 4 && (
                                            <button onClick={prevStep} className="py-1 px-4 bg-blue-500 text-white rounded-sm">
                                                Back
                                            </button>
                                        )}
                                        {step < steps.length && step < 3 && (
                                            <button onClick={nextStep} className="ml-4 py-1 px-4 bg-blue-500 text-white rounded-sm">
                                                Next
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegisterPartner;
