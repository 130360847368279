import React, { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../utils/axios';
import { GoogleMap, Marker, Autocomplete, useJsApiLoader } from '@react-google-maps/api';

const StoreSection = ({ storeData, setStoreData, validationErrors }) => {
    const [storeName, setStoreName] = useState(storeData.storeName || '');
    const [drugLicNum, setDrugLicNum] = useState(storeData.drugLicNum || '');
    const [latitude, setLatitude] = useState(storeData.latitude || 0);
    const [longitude, setLongitude] = useState(storeData.longitude || 0);
    const [markerPosition, setMarkerPosition] = useState({ lat: latitude, lng: longitude });
    const [mapCenter, setMapCenter] = useState({ lat: latitude, lng: longitude });
    const [searchText, setSearchText] = useState(storeData.locationDescription || '');
    const [selectedFile, setSelectedFile] = useState(storeData.selectedFile || null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: '',//AIzaSyA4Vzs1VRiOO0Sc4MPFDwgRVcVdmfeJSqQ
        libraries: ['places'],
    });

    const mapOptions = {
        disableDefaultUI: true,   // Disables all default UI
        zoomControl: false,       // Disables zoom control
        streetViewControl: false, // Disables street view
        mapTypeControl: false,    // Disables map type switch
        fullscreenControl: false, // Disables fullscreen control
    };

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);
                setMarkerPosition({ lat: latitude, lng: longitude });
                setMapCenter({ lat: latitude, lng: longitude });
                setLocationDescription(latitude, longitude);
            });
        }
    }, []);

    useEffect(() => {
        setStoreData({
            storeName,
            drugLicNum,
            latitude,
            longitude,
            locationDescription: searchText,
            selectedFile,
        });
    }, [storeName, drugLicNum, latitude, longitude, searchText, selectedFile, setStoreData]);

    useEffect(() => {
        fetchPartnerDetails();
    }, []);

    const fetchPartnerDetails = async () => {
        try {
            const token = JSON.parse(sessionStorage.getItem('userToken'));
            const user = JSON.parse(sessionStorage.getItem('user'));
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_STORE_URL}/store`, {
              partnerId: user._id,
            }
        );
            if (response.status === 200) 
            {
                if (typeof(response.data[0]) === "undefined") {
                    return;
                }
                const result = response.data[0];
                setStoreName(result.storeName);
                setDrugLicNum(result.drugLicenseNumber);
                setLatitude(result.storeLatitude);
                setLongitude(result.storeLongitude);
                const url = result.drugLicenseImg;
                const parsedUrl = new URL(url);
                const fileName = parsedUrl.pathname.split('/').pop();
                setSelectedFile({ name: fileName, url: result.drugLicenseImg });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleMarkerDragEnd = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setLatitude(lat);
        setLongitude(lng);
        setMarkerPosition({ lat, lng });
        setLocationDescription(lat, lng);
    }, []);

    const setLocationDescription = useCallback((lat, lng) => {
        if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
            console.error('Google Maps API is not loaded properly');
            return;
        }
        
        const geocoder = new window.google.maps.Geocoder();
        const latlng = new window.google.maps.LatLng(lat, lng);
    
        geocoder.geocode({ location: latlng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setSearchText(results[0].formatted_address);
            } else {
                console.error('Geocoder failed due to: ' + status);
            }
        });
    }, []);
    

    const handleSearchInputChange = (e) => {
        const text = e.target.value;
        setSearchText(text);
    };

    const handleStoreNameChange = (e) => {
        const name = e.target.value;
        setStoreName(name);
    };

    const handleDrugLicNumChange = (e) => {
        const num = e.target.value;
        setDrugLicNum(num);
    };

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    if (!isLoaded) {
        return <div>Loading Map...</div>;
    }

    return (
        <div className="store-section text-left">
            {/* Store Name, Drug License Fields */}
            <div className="pt-1">
                <label className="block text-sm text-start font-medium text-gray-600">Store Name</label>
                <input
                    type="text"
                    id="storeName"
                    className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.storeName ? 'error-border' : ''
                    }`}
                    placeholder="Store Name"
                    value={storeName}
                    onChange={handleStoreNameChange}
                />
            </div>
            <div className="pt-1">
                <label className="block text-sm font-medium text-gray-600">Drug License(Number)</label>
                <input
                    type="text"
                    id="drugLicenseNum"
                    className={`w-full h-10 p-2 border rounded-md ${
                        validationErrors.drugLicNum ? 'error-border' : ''
                    }`}
                    placeholder="ABC123"
                    value={drugLicNum}
                    onChange={handleDrugLicNumChange}
                />
            </div>

            {/* File Input */}
            <div className="pt-1">
                <label className="block text-sm items-start font-medium text-gray-600">Drug License(Scan)</label>
                <input type="file" id="drugLicenseScan" className="hidden" onChange={handleFileInputChange} />
                <label htmlFor="drugLicenseScan" className="py-2 w-full block border rounded-md cursor-pointer">
                    <span className="p-1 ml-1 bg-blue-500 border rounded-md text-white text-center">Choose File</span>
                    <span className="pl-1 text-gray-400">{selectedFile ? selectedFile.name : 'No file chosen'}</span>
                </label>
            </div>

            {/* Map Component */}
            <div className="map-container pt-1 text-left">
                <label>Store Location:</label>
                <div className="flex gap-1 mb-2">
                    <input
                        type="text"
                        id="locationSearch"
                        className="w-full h-10 p-2 border rounded-md"
                        placeholder="Search for a location"
                        value={searchText}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '200px', boxShadow: '0 0 5px gray' }}
                    center={mapCenter}
                    zoom={15}
                    options={mapOptions}
                    onClick={(e) => {
                        const lat = e.latLng.lat();
                        const lng = e.latLng.lng();
                        setLatitude(lat);
                        setLongitude(lng);
                        setMarkerPosition({ lat, lng });
                        setMapCenter({ lat, lng });
                    }}
                >
                    <Marker
                        position={markerPosition}
                        draggable={true}
                        onDragEnd={handleMarkerDragEnd}
                    />
                </GoogleMap>
            </div>
        </div>
    );
};

export default StoreSection;
